import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import BtnEllipse from './components/BtnEllipse';

import './App.scss';
import Container from './pages/Container';
import Footer from './components/Footer';


function App() {
  const url_string = window.location.href;
  const url = new URL(url_string);
  const query = url.searchParams.get("query");
  // const queryFinal = query.replaceAll(' ', '%2B');
  const queryFinal = query.split(' ').join('%2B');

  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [modalError400IsOpen, setModalError400IsOpen] = useState(false);
  const [message, setMessage] = useState('Msg');
  const [data, setData] = useState([]);
  const windowLocation = 'https://vipwallet-sandbox.vivi-th.com';
  // const windowParentLocation = 'https://qa-mypmi.cs80.force.com/dteth/s/wallet';
  // const windowParentLocation = "/main/?query=" + query;


  useEffect(() => {


    // Get API: Login Token
    async function fetchDataLogin() {
      let apiLocation = '';
      let apiXKey = ""

      console.log(window.location.origin);
      switch(window.location.origin) {
        // Local
        case "http://localhost:3000": 
          apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
          apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
          break;

        // Staging
        case "https://vipwallet-sandbox.vivi-th.com":
          apiLocation = 'api.vipwallet-sandbox.vivi-th.com';
          apiXKey = "63f016c6-5d14-4ed9-9091-a17d2b372fdd"
          break;

        // Production
        default:
          apiLocation = 'api.vipwallet.vivi-th.com';
          apiXKey = "756dc69c-4c3e-4724-aa3d-0cf27010c102";
          break;
      }

      const res = await fetch(`https://${apiLocation}/wallet/api/user/login`, {
        method: 'POST',
        body: JSON.stringify({
          "query": queryFinal
        }),
        headers: {
          "x-application-secret-key": apiXKey,
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      });

      res
        .json()
        .then(res => {
          if (res.success == 0) {
            setModalError400IsOpen(true);

          } else {
            const data = res;
            if (data.active_campaign == 0) {
              setMessage('คุณไม่ได้อยู่ใน Campaign');
              setModalErrorIsOpen(true);
              setIsLoaded(true);

            } else {
              localStorage.setItem('accessToken', data.token);
              localStorage.setItem('userId', data.user);
              localStorage.setItem('query', query);
              localStorage.setItem('windowParentLocation', data.campaign_data.widget_url);
              localStorage.setItem('windowLocation', windowLocation);
              localStorage.setItem('apiLocation', apiLocation);

              setData(data);
              setIsLoaded(true);
            }
          }

        })
        .catch(err => {
          console.log(err);
          setMessage('การเข้าสู่ระบบเกิดข้อผิดพลาด')
          setModalErrorIsOpen(true);
        });
    }

    fetchDataLogin();
  }, []);

  function handleReload() {
    setModalErrorIsOpen(false);
    window.parent.location = "/main/?query=" + query;
  }

  function handleReloadWallet() {
    setModalErrorIsOpen(false);
    // eslint-disable-next-line no-undef
    window.parent.location = windowParentLocation;
  }

  Modal.setAppElement('#root');

  if(modalErrorIsOpen) {
    return (<Modal
      isOpen={modalErrorIsOpen}
      className="_modal box-style app"
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.5)'
        }
      }
      }
    >
      <div className="_error-modal center">
        <p className="font-black">{message} {error}</p>
        <p className="font-black">กรุณาลองอีกครั้ง</p>
        <br />
        <a onClick={handleReloadWallet} >
          <BtnEllipse name="กลับหน้าหลัก" color="blue" />
        </a>
      </div>
    </Modal>)
  } else if (!isLoaded) {
    return (
      <div className="container loading">
        <div className="container-top">
          <h3>Loading...</h3>
        </div>
      </div>
    );
  } else {
    return (

      <div>
        {/* <Header /> */}
        {/* <NavMain /> */}
        <Container data={data} />

        {/* <Footer /> */}

        <Modal
          isOpen={modalError400IsOpen}
          className="_modal box-style app"
          style={{
            overlay: {
              backgroundColor: 'rgba(0,0,0,0.5)'
            }
          }
          }
        >
          <div className="_error-modal center">
            <p className="font-black">{message} {error}</p>
            <p className="font-black">กรุณาลองอีกครั้ง</p>
            <br />
            <a onClick={handleReload} >
              <BtnEllipse name="กลับหน้าหลัก" color="blue" />
            </a>
          </div>
        </Modal>
      </div>
    );
  }
}

export default App;
