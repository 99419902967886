import React, { useState } from 'react';

import ConfirmReceiveCouponAction from '../components/ConfirmReceiveCouponAction';

function ConfirmReceiveCoupon(props) {

    return (
        <React.Fragment>
            <div className="container-top">
                <h3>&nbsp;</h3>
            </div>
            <ConfirmReceiveCouponAction data={props.data} />
        </React.Fragment>
    )
}

export default ConfirmReceiveCoupon;