import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from './BtnEllipse';

import IconStar from '../assets/img/ic-star.svg';

function ConfirmBuyCoinAction(props) {
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [modalErrorResIsOpen, setModalErrorResIsOpen] = useState(false);
    const [openButton, setOpenButton] = useState(false);
    const [closeButtonConfirm, setCloseButtonConfirm] = useState(false);
    const [message, setMessage] = useState('');
    const [messageErrorRes, setMessageErrorRes] = useState('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');

    const [points, setPoints] = useState(props.data.points);

    const [dataPoint, setDataPoint] = useState(JSON.parse(localStorage.getItem('dataBuyCoin')).point);
    const [dataCoin, setDataCoin] = useState(JSON.parse(localStorage.getItem('dataBuyCoin')).coin);
    const [dataPointsResult, setDataPointsResult] = useState(0);

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation');

    useEffect(() => {
        function resultPoint() {
            let pointInt = '';
            // (dataPoint.length > 3) ? pointInt = dataPoint.replaceAll(',', '') : pointInt = dataPoint;
            (dataPoint.length > 3) ? pointInt = dataPoint.split(',').join('') : pointInt = dataPoint;
            const dataPointsResult = points - parseInt(pointInt);
            setDataPointsResult(dataPointsResult);
        }
        
        resultPoint();

        setIsLoaded(true);
    }, [])

    async function setDataResult() {
        setCloseButtonConfirm(true);
        // setModalIsOpen(true);
        // setIsLoaded(true);
        // setOpenButton(false);
        // const burnPoint = parseInt((dataPoint.length > 3) ? dataPoint.replaceAll(',', '') : dataPoint);
        const burnPoint = parseInt((dataPoint.length > 3) ? dataPoint.split(',').join('') : dataPoint);

        const dataResult = JSON.stringify(
            {
                "burn_point": burnPoint,
            }
        );
        // setModalIsOpen(false);

        const res = await fetch(`https://${apiLocation}/wallet/api/user/points_to_coins`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: dataResult
        });

        res
        .json()
        .then(res => {
            if(res.success == 0) { // error succees: 0
                setModalErrorResIsOpen(true);
                setMessageErrorRes(res.message);
            } else { // succees: 1
                setModalErrorResIsOpen(false);
                setModalIsOpen(true);
                setIsLoaded(true);
                setOpenButton(true);
            }
        })
        .catch(err => { 
            setError(true);
            setMessage(err);
            // setModalErrorIsOpen(true);
            setIsLoaded(true);
        });
    }
    
    function handleReloadError() {
        setModalErrorIsOpen(false);
        window.parent.location = "/main/?query="+query;

    }

    function handleReload() {
        setModalIsOpen(false);
        window.parent.location = windowParentLocation;
    }


    Modal.setAppElement('#root');

    if(error) {
        return (
            <Modal 
            isOpen={modalErrorIsOpen} 
            className="_modal box-style modal-confirmbuycoinaction" 
            style={{ 
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.5)'
                }}
            }
            >
            <div className="_error-modal center">
                <p className="font-black">เกิดข้อผิดพลาดจากการทำรายการ</p>
                {message}
                <p className="font-black">กรุณาลองอีกครั้ง</p>
                <br/>
                <a onClick={handleReloadError()} >
                    <BtnEllipse name="กลับหน้าหลัก" color="blue"/>
                </a>
            </div>
            </Modal>
        );
      } else if(!isLoaded) {
        return (
            <div className="container loading" style={{ height: '80vh'}}>
                <div className="container-top">
                    <h3>Loading...</h3>
                </div>
            </div>
        );
      } else {
        return (
            <div className="checkcoin-action">
                <div className="confirm-box box-style">
                    <p className="font-black">จำนวนที่ได้รับ</p>
                    <div className="_result">
                        <img src={IconStar} alt="icon" />
                        <span className="font-blue">{ dataCoin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</span>
                        <span className="font-blue"> เหรียญ</span>
                    </div>
                </div>
                <div className="summary-box box-style">
                    <div className="_point _display-flex">
                        <div className="_left">
                            <span>คะแนน VIP ที่ใช้</span>
                        </div>
                        <div className="_right">
                            <span className="font-black">{ dataPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</span>
                            <span>คะแนน</span>
                        </div>
                    </div>
                    <div className="_balance _display-flex">
                        <div className="_left">
                            <span>คงเหลือ</span>
                        </div>
                        <div className="_right">
                            <span className="font-black">{ dataPointsResult.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</span>
                            <span>คะแนน</span>
                        </div>
                    </div>
                </div>
                <Link to={`/buycoin/?query=${query}`}>
                    <BtnEllipse name="ยกเลิก" color="grey" />
                </Link>
                
                { dataPointsResult < 0 || closeButtonConfirm ? <a style={{opacity: '0.5'}}><BtnEllipse name="ยืนยัน" color="blue" /></a> : <a onClick={()=>setDataResult()} ><BtnEllipse name="ยืนยัน" color="blue" /></a> }
                
                <Modal 
                    isOpen={modalIsOpen} 
                    className="_modal box-style confirmbuycoinaction" 
                    style={{ 
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.5)'
                        }}
                    }
                >
                    <div className="_result center">
                        <p className="font-black">คุณได้รับ</p>
                        <div>
                            <img src={IconStar} alt="icon" />
                            <span className="font-blue">{dataCoin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                            <span className="font-blue"> เหรียญ</span>
                        </div>
                        {
                            !openButton ? <p>Loading...</p> : <a onClick={()=>handleReload()} style={{ opacity: '1'}} ><BtnEllipse name="กลับหน้าหลัก" color="blue" /></a>
                        }
                        
                    </div>
                </Modal>

                <Modal 
                    isOpen={modalErrorResIsOpen} 
                    className="_modal box-style confirmbuycoinaction" 
                    style={{ 
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.5)'
                        }}
                    }
                >
                    <div className="_result center">
                        {/* <p className="font-black">{messageErrorRes}</p> */}
                        <p className="font-black" dangerouslySetInnerHTML={{__html: messageErrorRes}}></p>
                        <a onClick={()=>handleReload()} style={{ opacity: '1'}} ><BtnEllipse name="กลับหน้าหลัก" color="blue" /></a> 
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(ConfirmBuyCoinAction);
