import DBR from "../dbr";
import React, { useState, useEffect, useRef } from 'react';
import { Redirect, Link, Router, withRouter, useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from '../components/BtnEllipse';

import QrScanner from '../assets/img/qrscanner.svg';
import IconBack from '../assets/img/ic-back.svg';
import IconImg from '../assets/img/ic-img.svg';

function ScanQrCode(props) {
    let history = useHistory();

    const reader = null;
    const refDivMessage = React.createRef();

    const bDestroyed = false;
    const scanner = null;
    const elRef = React.createRef();
    const inputFile = useRef(null);

    const [messages, setMessages] = useState([]);
    const [bShowScanner, setBShowScanner] = useState(false);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('เกิดข้อผิดพลาดจากการทำงานของระบบ กรุณาลองใหม่');
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [dataScanQrCode, setDataScanQrCode] = useState('ScanQrcode');

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    // eslint-disable-next-line no-unused-vars
    const windowParentLocation = localStorage.getItem('windowParentLocation'); 

    let dataQrCode = '';
    let vdoScanner = null;

    useEffect(() => {
        // Video Scanner (Start)

        callVdoScanner();

        if(reader){
            reader.destroy();
        }
    }, []);

    // Set data after scan
    async function setDataAfterScan(txt) {
        setDataScanQrCode(txt);
        const dataMsg = JSON.stringify(
            {
                "qrcode": txt
            }
        );
        const res = await fetch(`https://${apiLocation}/wallet/api/user/scan_qrcode`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: dataMsg
        });

        res
        .json()
        .then(res => {
            const data = res;
            vdoScanner.close();
            if (res.success === 0) {
                if(data.message.name == "TokenExpiredError") {
                    setErrorMsg("QR นี้ไม่สามารถใช้ได้ กรุณาใช้ QR ใหม่");
                } else if(data.message.name == "JsonWebTokenError") {
                    setErrorMsg("QR นี้ไม่ถูกต้อง กรุณาใช้ QR ใหม่");
                } else {
                    setErrorMsg(data.message.message);
                }
                setModalErrorIsOpen(true);

            } else if(data == "Unauthorized 301") {
                setErrorMsg("QR นี้ไม่สามารถใช้ได้แล้ว กรุณาใช้ QR ใหม่");
                setModalErrorIsOpen(true);

            } else {
                console.log(res);
                history.push({
                    pathname: '/receivecoupon/',
                    search: '?query='+query,
                    state: { 
                        qrcode: txt,
                        firstname: data.firstname,
                        lastname: data.lastname,
                        qr_refid: data.qr_refid,
                        conv_coin: data.campaign_qr.conv_coin,
                        conv_point: data.campaign_qr.conv_point,
                        discount_baht: data.campaign_qr.discount_baht,
                        discount_coin: data.campaign_qr.discount_coin,
                        markerup_percent: data.campaign_qr.markerup_percent,
                    }
                })

                localStorage.setItem('scanQrRefId', data.qr_refid);
                setModalIsOpen(false);
            }
        })
        .catch(err => { 
            // setError(err);
            setModalErrorIsOpen(true);
            // setIsLoaded(true);
            console.log(err);
        });
    }

    // Show message
    const appendMessage = str => {
        setMessages(state=>(
            [...state, str]
        ));
    }

    async function callVdoScanner () {

        try{
            vdoScanner = scanner || await DBR.BarcodeScanner.createInstance();
            if(bDestroyed){
                vdoScanner.destroy();
                return;
            }
            vdoScanner.setUIElement(elRef.current);
            vdoScanner.onFrameRead = results => {
                if(results.length){
                    // console.log(results);
                }
            };
            vdoScanner.onUnduplicatedRead = (txt, result) => {
                setDataAfterScan(txt);
                // vdoScanner.close();
            };
            await vdoScanner.open();

        }catch(ex){
            appendMessage(ex.message);
            console.error(ex);
        }
    }

    // Image Scanner (Start)
    const onIptChange = event=>{
        // React can't get event.target in async func by default.
        // Thus get event.target in sync part.
        let input = event.target;

        (async ()=>{
            try{
                let imgScanner = reader || await DBR.BarcodeReader.createInstance();
                let files = input.files;
                for(let i = 0; i < files.length; ++i){
                    let file = files[i];
                    let results = await imgScanner.decode(file);
                    for(let result of results){
                        dataQrCode = result;
                        setDataAfterScan(result.barcodeText);
                    }
                }
                input.value = "";

            }catch(ex){
                appendMessage(ex.message);
                console.error(ex);
            }
        })();
    }

    const showScanner = ()=>{
        setBShowScanner(true);
    }

    const hideScanner = ()=>{
        setBShowScanner(false);
    }

    const onButtonClick = () => {
        inputFile.current.click();
    }

    function handleReload() {
        setModalErrorIsOpen(false);
        // props.history.push(`/main/?query=${query}`);
        window.parent.location = "/main/?query="+query;
      }

      function handleReloadCamera() {
        hideScanner();
        // props.history.push(`/main/?query=${query}`);
        window.parent.location = "/main/?query="+query;
      }

    return (
        <div className="scanqrcode-box">

            {/* Image Scanner */}
            <div className="_browsefile">
                <input type='file' id='file'ref={inputFile} onChange={onIptChange} multiple accept="image/png,image/jpeg,image/bmp,image/gif" style={{display: 'none'}}/>
                <button onClick={onButtonClick}>
                    <img src={IconImg} alt="icon" />
                </button>
            </div>

            {/* Vdo Scanner */}
            <div ref={elRef}  id="div-video-container">
                <div className="_header bg-gradient-blue">
                    <a onClick={handleReloadCamera}>
                        <div className="btn-back dbrScanner-btn-close">
                            <img src={IconBack} alt="icon" />
                        </div>
                    </a>
                    <h3>สแกน QR</h3>
                </div>
                <img className="qrscanner" src={QrScanner} alt="qrscanner"/>
                <svg className="dbrScanner-bg-loading" width="20px" height="20px" viewBox="0 0 1792 1792"><path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path></svg>
                <video className="dbrScanner-video" playsInline={true} width={window.innerWidth} height={window.innerHeight}></video>
                <div className="_footer bg-gradient-blue">
                    <p>ให้ตำแหน่ง QR code อยู่ตรงกลางภาพ</p>
                </div>
            </div>

            <Modal 
            isOpen={modalErrorIsOpen} 
            className="_modal box-style modal-scanqrcode" 
            style={{ 
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: 3
                }}
            }
            >
                <div className="_error-modal center">
                    <p>{ messages } {errorMsg}</p>
                    <br/>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue"/>
                    </a>
                </div>
            </Modal>
        </div>
    )
}

export default withRouter(ScanQrCode);
