import React, { useState, useEffect } from 'react';
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route, Router } from 'react-router-dom';


import ApolloClient from "../graphql/client";
import { EventSubscription } from "../components/EventSubscription";

import Main from './Main';
import BuyCoin from './BuyCoin';
import ConfirmBuyCoin from './ConfirmBuyCoin';
import CreateCoupon from './CreateCoupon';
import CreateCouponSku from './CreateCouponSku';
import ConfirmCreateCoupon from './ConfirmCreateCoupon';
import ReceiveCoupon from './ReceiveCoupon';
import ConfirmReceiveCoupon from './ConfirmReceiveCoupon';
import QrCodeOptional from './QrCodeOptional';
import QrCodeScanner from './QrCodeScanner'; // >> cam: Html5-qrcode, img: React-qr-scanner
// import QrCodeScan from "./QrCodeScan"; // [Auto UI] >> cam: Html5-qrcode, img: Html5-qrcode
// import QrScanner from './QrScanner'; // on Production >> cam: Html5-qrcode, img: React-qr-scanner
import ScanQrCode from './ScanQrCode';
import Landing from './Landing';
import CreateCouponSkuSales from './CreateCouponSkuSales';
import ConfirmCreateCouponSales from './ConfirmCreateCouponSales';

function Container(props) {
    const [data, setData] = useState(props.data);

    useEffect(() => {

    }, []);

    return (
        <div className="container" style={{minHeight: window.innerHeight - 85, overflow: 'auto' }}>
            <ApolloProvider client={ApolloClient}>
                <BrowserRouter>
                    {/* <Main data={props.data} /> */}
                    <EventSubscription />
                    <Route exact path="/" render={()=> <Landing /> } />
                    <Route path="/main" render={()=> <Main data={data} /> }/>
                    <Route path="/buycoin" render={()=> <BuyCoin data={data} />} />
                    <Route path="/confirmbuycoin" render={()=> <ConfirmBuyCoin data={data} />} />
                    <Route path="/createcoupon" render={()=> <CreateCoupon data={data} />} />
                    <Route path="/createcouponsku" render={()=> <CreateCouponSku data={data} />} />
                    <Route path="/createcouponskusales" render={()=> <CreateCouponSkuSales data={data} />} />
                    <Route path="/confirmcreatecoupon" render={()=> <ConfirmCreateCoupon data={data} />} />
                    <Route path="/confirmcreatecouponsales" render={()=> <ConfirmCreateCouponSales data={data}  />} />
                    <Route path="/receivecoupon" render={()=> <ReceiveCoupon data={data} />} />
                    <Route path="/confirmreceivecoupon" render={()=> <ConfirmReceiveCoupon data={data} />} />
                    <Route path="/scanqrcode" render={()=> <QrCodeScanner />} />
                    <Route path="/scanqrcodeoptional" render={()=> <QrCodeOptional />} />
                </BrowserRouter>
            </ApolloProvider>
        </div>
    );
}

export default Container;
