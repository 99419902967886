import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from './BtnEllipse';

import IconTicket from '../assets/img/ic-ticket.svg';
import IconStar from '../assets/img/ic-star.svg';

function ConfirmReceiveCouponAction(props) {
    const [dataConfirmReceiveCoupon, setDataConfirmReceiveCoupon] = useState(JSON.parse(localStorage.getItem('dataConfirmReceiveCoupon')));
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [message, setMessage] = useState('');

    
    const [dataQrRefId, setDataQrRefId] = useState(localStorage.getItem('scanQrRefId'));
    const [dataGenUser, setDataGenUser] = useState('GenUser');
    const [dataQrGetBahts, setDataQrGetBahts] = useState(0);
    const [dataQrStatus, setDataQrStatus] = useState('QrStatus');
    const [dataScanGetCoins, setDataScanGetCoins] = useState(0);
    const [dataUserType, setDataUserType] = useState('UserType');
    const [dataTimeStamp, setDataTimeStamp] = useState('');

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation');

    useEffect(() => {
        // console.log(dataQrRefId);
        async function getResultDataDiscount() {
           
            const res = await fetch(`https://${apiLocation}/wallet/api/user/qr_status/`+dataQrRefId, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });

        res
            .json()
            .then(res => {if(res.success == 0) {
                setModalErrorIsOpen(true);
              } else {
                // console.log(res)
                const data = res;
                setDataQrRefId(data.qr_refid);
                setDataGenUser(data.gen_user);
                setDataQrGetBahts(data.qr_get_bahts);
                setDataQrStatus(data.qr_status);
                setDataScanGetCoins(data.scan_get_coins);
                setDataUserType(data.user_type);
                setDataTimeStamp(data.ts_str);
              }
            })
            .catch(err => { 
                setError(err);
                setModalErrorIsOpen(true);
                setIsLoaded(true);
            });
        }

        getResultDataDiscount();

    }, [])

    function handleReload() {
        window.parent.location = windowParentLocation;
    }

    Modal.setAppElement('#root');

    return (
        <div className="createcoupon-action">
            <div className="ticket-box box-style">
                <div className="_header" style={{ marginBottom: '20px' }}>
                    <h3 className="font-black">ส่วนลดที่ให้</h3>
                    <p className="font-black" style={{ fontSize: '1.4rem' }}>คุณ  {dataGenUser}</p>
                    <div className="_resultSummary">
                        <img src={IconTicket} alt="icon" />
                        <span className="font-black">{dataQrGetBahts}</span>
                        <span className="font-black"> บาท</span>
                    </div>
                    <p>{dataTimeStamp}</p>
                    <p>รหัสอ้างอิง: <span>{dataQrRefId}</span></p>
                </div>
                <div className="_content">
                    <hr/>
                </div>
                <div className="_footer">
                    <h3 className="font-black">คุณทำได้รับ</h3>
                    <div className="_resultSummary">
                        <img src={IconStar} alt="icon" />
                        <span className="font-blue">{dataScanGetCoins}</span>
                        <span className="font-blue"> เหรียญ</span>
                    </div>
                </div>
                <div className="ticket-footer"></div>
            </div>

            <a onClick={()=>handleReload()} >
                <BtnEllipse name="กลับหน้าหลัก" color="blue" />
            </a>

            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-createcoupon" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_error-modal center">
                    <p className="font-black">เกิดข้อผิดพลาดจากการสร้าง QR code</p>
                    {message}
                    <p className="font-black">กรุณาลองอีกครั้ง</p>
                    <br/>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue"/>
                    </a>
                </div>
            </Modal>
        </div>
    )
}

export default withRouter(ConfirmReceiveCouponAction);
