import React, { useState, useEffect } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from '../components/BtnEllipse';

import ConfirmCreateCouponSalesAction from '../components/ConfirmCreateCouponSalesAction';

function ConfirmCreateCouponSales(props) {
    const location = useLocation();

    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);

    const [dataSalesChargeCoins, setDataSalesChargeCoins] = useState(location.state.ask_coins);
    const [dataSalesGetBaht, setDataSalesGetBaht] = useState(location.state.ask_discount);
    const [dataSalesRefId, setDataSalesRefId] = useState(location.state.sales_refid);
    const [dataCoinsLeft, setDataCoinsLeft] = useState(location.state.coins);
    const [message, setMessage] = useState('Msg');

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation');

    useEffect(() => {

    }, [])

    function handleReload() {
        // props.history.push(`/main/?query=${query}`);
        window.parent.location = "/main/?query="+query;
    }

    Modal.setAppElement('#root');

    return (
        <React.Fragment>
            <div className="container-top">
                <h3 style={{ marginBottom: '15px'}}>ยืนยันส่วนลด</h3>
                <p className="font-white" style={{ marginBottom: '10px', fontSize: '1.2rem'}}>รหัสอ้างอิง: <span>{ dataSalesRefId }</span></p>
            </div>
            <ConfirmCreateCouponSalesAction dataSalesRefId={dataSalesRefId} dataSalesChargeCoins={dataSalesChargeCoins} dataSalesGetBaht={dataSalesGetBaht} dataCoinsLeft={dataCoinsLeft}  />
            
            <Modal 
                isOpen={modalIsOpen} 
                className="_modal box-style receivecouponaction" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_result center" style={{ padding: '60px 40px'}}>
                    <p>{message}</p>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue" />
                    </a>
                </div>
            </Modal>

            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-confirmcreatecouponaction-400" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_result center">
                    <p className="font-black" dangerouslySetInnerHTML={{__html: message}}></p>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue" />
                    </a>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(ConfirmCreateCouponSales);
