import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from '../components/BtnEllipse';

import ConfirmCreateCouponAction from '../components/ConfirmCreateCouponAction';

function ConfirmCreateCoupon(props) {
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);

    // const [dataUserType, setDataUserType] = useState();
    // const [dataQrStatus, setDataQrStatus] = useState();
    const [dataQrChargeCoins, setDataQrChargeCoins] = useState(0);
    const [dataQrGetBaht, setDataQrGetBaht] = useState(0);
    const [dataQrRefId, setDataQrRefId] = useState('QrRefID');
    const [dataScanUser, setDataScanUser] = useState('ScanUser');
    const [dataCoinsLeft, setDataCoinsLeft] = useState(0);
    const [message, setMessage] = useState('Msg');

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation');

    useEffect(() => {
      const genQrRefId = localStorage.getItem('genQrRefId');
      
    //   console.log(genQrRefId);

      async function fetchDataConfirmCreateCoupon() {
        const res = await fetch(`https://${apiLocation}/wallet/api/user/qr_status/`+genQrRefId, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        });
        
        res
        .json()
        .then(res => {
            console.log(res);

            if(res.success == 0) {
                setModalErrorIsOpen(true);
                setMessage(res.message);
            } else {

                setDataQrRefId(res.qr_refid);
                setDataScanUser(res.scan_user);
                setDataQrChargeCoins(res.qr_charge_coins);
                setDataQrGetBaht(res.qr_get_bahts);
                setDataCoinsLeft(res.coins_left);
                // console.log(JSON.parse(localStorage.getItem('query')).data);

                setIsLoaded(true);
            }

            waitConfirm();
        })
        .catch(err => { 
            setError(err);
            setModalErrorIsOpen(true);
            setIsLoaded(true);
        });
    }

    fetchDataConfirmCreateCoupon();
    }, [])

    function waitConfirm() {
        console.log('waiting....')
        setTimeout(() => {
            setModalIsOpen(true);
            setMessage('คุณทำรายการเกินเวลากำหนด');
        }, 175000);
        
        setTimeout(() => {
            setModalIsOpen(false);
            handleReload();
        }, 180000);
    }

    function handleReload() {
        // props.history.push(`/main/?query=${query}`);
        window.parent.location = "/main/?query="+query;
    }

    Modal.setAppElement('#root');

    return (
        <React.Fragment>
            <div className="container-top">
                <h3 style={{ marginBottom: '15px'}}>ยืนยันส่วนลด</h3>
                <p className="font-white" style={{ marginBottom: '10px', fontSize: '1.2rem'}}>รหัสอ้างอิง: <span>{ dataQrRefId }</span></p>
            </div>
            <ConfirmCreateCouponAction data={props.data} dataQrRefId={dataQrRefId} dataScanUser={dataScanUser} dataQrChargeCoins={dataQrChargeCoins} dataQrGetBaht={dataQrGetBaht} dataCoinsLeft={dataCoinsLeft}  />
            
            <Modal 
                isOpen={modalIsOpen} 
                className="_modal box-style receivecouponaction" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_result center" style={{ padding: '60px 40px'}}>
                    <p>{message}</p>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue" />
                    </a>
                </div>
            </Modal>

            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-confirmcreatecouponaction-400" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_result center">
                    <p className="font-black" dangerouslySetInnerHTML={{__html: message}}></p>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue" />
                    </a>
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default withRouter(ConfirmCreateCoupon);
