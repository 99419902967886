import React from 'react';
import BtnEllipse from '../components/BtnEllipse';

function Landing() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const query = url.searchParams.get("query"); 

    function handleReload() {
        // props.history.push(`/mian/?query=${query}`);
        window.parent.location = "/main/?query="+query;
    }

    return (
        <div className="landing">
            <div className="landing-box">
                <h1 className="font-black">VIP WALLET</h1>
                <h3>ระบบแลกเหรียญและคะแนน</h3>
                <a onClick={()=>handleReload()}>
                    <BtnEllipse name="เข้าสู่ระบบ" color="blue" />
                </a>
            </div>
        </div>
    )
}

export default Landing;
