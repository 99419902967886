import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from './BtnEllipse';

import IconTicket from '../assets/img/ic-ticket.svg';

function ConfirmCreateCouponSalesAction(props) {
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [dataSalesGetBaht, setDataSalesGetBaht] = useState(props.dataSalesGetBaht);
     
    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation');

    const dataCoinsLeft = props.dataCoinsLeft;
    const [dataCoinsBalance, setDataCoinsBalance] = useState(props.dataCoinsLeft - props.dataSalesChargeCoins);

    const [salesRefId, setSalesRefId] = useState(props.dataSalesRefId);
    
    useEffect(() => {

    }, [])

    async function setDataDiscount(action) {
        setIsLoaded(false);
        const userAction = action;
        const dataSalesRefId = salesRefId;

        const dataResult = JSON.stringify(
            {  "refid": dataSalesRefId ,
                "action_discount": userAction
            }   
        );

        // console.log(dataResult);
        setModalIsOpen(false);

        const res = await fetch(`https://${apiLocation}/wallet/api/user/sales_confirm`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: dataResult
        });

        res
        .json()
        .then(res => {
            setModalIsOpen(false);
            setMessage(res.message);
            // console.log(res);

            if (res.success == 0) {
                setModalErrorIsOpen(true);
                setMessage(res.message);
    
            } else {
                if(userAction == 'accept'){
                    setModalIsOpen(true);
                } else {
                    window.parent.location = "/main/?query="+query;
                }
                // props.history.push(`/main/?query=${query}`);
                // window.parent.location = "/main/?query="+query;
            }
        })
        .catch(err => { 
            setError(err);
            setModalErrorIsOpen(true);
            setIsLoaded(true);
        });
    }

    function handleReload() {
        setModalIsOpen(false);
        // props.history.push(`/main/?query=${query}`);
        window.parent.location = windowParentLocation;
    }

    Modal.setAppElement('#root');
    return (
        <div className="checkcoin-action">
            <div className="confirm-box box-style">
                <p className="font-black">ต้องการส่วนลดจากพนักงาน</p>
                <div className="_result">
                    <img src={IconTicket} alt="icon" />
                    <span className="font-blue">{props.dataSalesGetBaht.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    <span className="font-blue"> บาท</span>
                </div>
            </div>
            <div className="summary-box box-style">
                <div className="_point _display-flex">
                    <div className="_left">
                        <span>จำนวนเหรียญที่ใช้</span>
                    </div>
                    <div className="_right">
                        <span className="font-black">{props.dataSalesChargeCoins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                        <span>เหรียญ</span>
                    </div>
                </div>
                <div className="_balance _display-flex">
                    <div className="_left">
                        <span>คงเหลือ</span>
                    </div>
                    <div className="_right">
                        { dataCoinsBalance >= 0 ? <span className="font-black">{dataCoinsBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> : <span className="font-red">{dataCoinsBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>}
                        <span>เหรียญ</span>
                    </div>
                </div>
                {dataCoinsLeft >= 0 ? '': <p className="font-red" style={{textAlign: 'left'}}>คุณมีเหรียญไม่เพียงพอ</p>}
            </div>

            <a onClick={()=>setDataDiscount("reject")}>
                <BtnEllipse name="ยกเลิก" color="grey" />
            </a>

            { dataCoinsLeft >= 0 ? <a onClick={()=>setDataDiscount("accept")} ><BtnEllipse name="ยืนยัน" color="blue" /></a> : <a style={{ opacity: '0.5'}}><BtnEllipse name="ยืนยัน" color="blue" /></a>}

            <Modal 
                isOpen={modalIsOpen} 
                className="_modal box-style modal-confirmcreatecouponaction" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_result center">
                    <p className="font-black">คุณได้รับส่วนลด</p>
                    <div>
                        <img src={IconTicket} alt="icon" />
                        <span className="font-blue">{dataSalesGetBaht}</span>
                        <span className="font-blue"> บาท</span>
                    </div>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue" />
                    </a>
                </div>
            </Modal>

            <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-confirmcreatecouponaction-400" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_result center">
                    <p className="font-black" dangerouslySetInnerHTML={{__html: message}}></p>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue" />
                    </a>
                </div>
            </Modal>
        </div>
    )
}

export default withRouter(ConfirmCreateCouponSalesAction);
