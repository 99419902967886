import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import IconBack from '../assets/img/ic-back.svg';

import ConfirmBuyCoinAction from '../components/ConfirmBuyCoinAction';

function ConfirmBuyCoin(props) {
    return (
        <React.Fragment>
            <div className="container-top">
                <Link to="/buycoin">
                    <div className="btn-back">
                        <img src={IconBack} alt="icon" />
                    </div>
                </Link>
                <h3>ตรวจสอบข้อมูล</h3>
            </div>
            <ConfirmBuyCoinAction data={props.data}  />
        </React.Fragment>
    )
}

export default ConfirmBuyCoin;
