import React, { useState, useEffect } from 'react';
import { gql } from "@apollo/client";
import { useSubscription } from "@apollo/react-hooks";

import { useHistory, Redirect } from 'react-router-dom';

const USERS_ADDED_SUBSCRIPTION = gql`
  subscription OnUserAdded {
    added {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export function EventSubscription(props) {
  const { loading, error, data } = useSubscription(USERS_ADDED_SUBSCRIPTION);
  // const history = useHistory();
  const url_string = window.location.href;
  const url = new URL(url_string);
  const query = url.searchParams.get("query"); 

  if (loading) return null;
  // if (error) console.log(`Error! ${error.message}`);
  if (error) {
    console.log(`Error! ${error.message}`);
    return false;
  } 

  if (!loading && data) {
    // const { added } = data;

    const userId = localStorage.getItem('userId');
    localStorage.setItem('msgDiscount', '');

    // console.log('added name:'+data.added.name);
    // console.log('added id: '+data.added.id);
    // console.log('user id: '+userId);

    if(data.added.id == userId ) {
      if(data.added.name == 'offer_discount') {
        return <Redirect to={`/confirmcreatecoupon/?query=${query}`} />;

      } else if(data.added.name == 'accept_discount') {
        localStorage.setItem('msgDiscount', data.added.name);
        // console.log('accept');
        return false;

      } else if(data.added.name == 'reject_discount') {
        localStorage.setItem('msgDiscount', data.added.name);
        // console.log('reject');
        return false;

      } else {
        return false;
      }
    } else {
      return false;
    }

  } else {
    return <h4>Listening...</h4>;
  }
}

export function SampleQuery(client) {
  client
    .query({
      query: gql`
        query Info {
          numberSix
          numberSeven

          user(id: 1) {
            id
            name
            createdAt
            updatedAt
          }

          users {
            id
            name
            createdAt
            updatedAt
          }
        }
      `,
    })
    .then((result) => console.log(result))
    .catch((err) => console.error(err));
}
