import React, { useState, useEffect } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from './BtnEllipse';

import IconTicket from '../assets/img/ic-ticket.svg';
import IconVIP from '../assets/img/ic-vip.svg';
import IconStar from '../assets/img/ic-star.svg';
import IconPlus from '../assets/img/ic-plus.svg';
import IconMinus from '../assets/img/ic-minus.svg';
import IconPlusActive from '../assets/img/ic-plus-a.svg';
import IconMinusActive from '../assets/img/ic-minus-a.svg';

function CouponReceiveAction(props) {
    const location = useLocation();

    const [error, setError] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [modalError400IsOpen, setModalError400IsOpen] = useState(false);
    const [modalProductIsOpen, setModalProductIsOpen] = useState(false);
    const [isLoaded, setIsLoaded]  = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [waitOffer, setWaitOffer] = useState(false);

    const [notEnoughPoint, setNotEnoughPoint] = useState(false);
    const [amountDiscount, setAmountDiscount] = useState(0);
    const [amountCoin, setAmountCoin] = useState(0);
    // const [convPoint, setConvPoint] = useState(props.dataStatus.campaign_data.conv_point);
    // const [convCoin, setConvCoin] = useState(props.dataStatus.campaign_data.conv_coin);
    const [currentPoint, setCurrentPoint] = useState(100000000);
    const [noTyping, setNoTyping] = useState(false);
    const [maxPoint, setMaxPoint] = useState(false);

    const [dataName, setDataName] = useState('Name');
    const [dataLastName, setDataLastName] = useState('Last');
    const [dataDiscountCoin, setDataDiscountCoin] = useState(0);
    const [dataDiscountBaht, setDataDiscountBaht] = useState(0);
    const [dataQrRefId, setDataQrRefId] = useState('qrcode refID');
    const [dataScanQrCode, setDataScanQrCode] = useState('qrcode');
    // const [dataConvCoin, setDataConvCoin] = useState(null);
    // const [dataConvPoint, setDataConvPoint] = useState(null);
    const [dataMarkupPercent, setDataMarkupPercent] = useState(0);
    // const [amountGetCoinCustomer, setAmountGetCoinCustomer] = useState(200);
    // eslint-disable-next-line no-unused-vars
    const [amountGetCoinVip, setAmountGetCoinVip] = useState(0);
    const [message, setMessage] = useState('msg');
    const [messageColor, setMessageColor] = useState('black');
    const [errorMessage, setErrorMessage] = useState('เกิดข้อผิดพลาดจากการทำรายการ กรุณาลองใหม่')

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation');

    const [data, setData] = useState({
        active_sku: props.data.active_sku || 0
    });
    const [maxSku, setMaxSku] = useState(0);
    const [skuResult, setSkuResult] = useState([]);
    const [askDiscount, setAskDiscount] = useState(props.dataFromScan.ask_discount)

    useEffect(() => {
        setIsLoaded(true);
        setDataName(location.state.firstname);
        setDataLastName(location.state.lastname);
        setDataQrRefId(location.state.qr_refid);
        setDataScanQrCode(location.state.qrcode);
        // setDataConvCoin(location.state.conv_coin);
        // setDataConvPoint(location.state.conv_point);
        setDataDiscountCoin(location.state.discount_coin);
        setDataDiscountBaht(location.state.discount_baht);
        setDataMarkupPercent(location.state.markerup_percent);
        localStorage.setItem('qr_refid', location.state.qr_refid);

        setMaxSku(location.state.max_discount);
        setAmountDiscount(location.state.ask_discount || 0);
        setSkuResult(location.state.sku_select);
        setAmountCoin(Math.ceil((location.state.ask_discount / location.state.discount_baht) * location.state.discount_coin || 0));
    }, [location]);

    // List Product for Modal Product SKU
    const listProduct = skuResult.map((item, index) => {
        return (
            <tr key={index}>
                <td>{item.sku_name}</td>
                <td>{item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                <td>{item.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                <td>{(item.amount * item.discount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
            </tr>
        )
    });
   
    
    function setMsgDiscount() {
        if(localStorage.getItem('msgDiscount') == 'accept_discount') {
            setWaitOffer(false);
            const acceptMsg = 'ตกลงข้อเสนอของคุณ';
            setMessage(acceptMsg);
            setMessageColor('green');
            setTimeout(() => {
                localStorage.setItem('msgDiscount','');
                props.history.push(`/confirmreceivecoupon/?query=${query}`);
            }, 3000);
        } else if(localStorage.getItem('msgDiscount') == 'reject_discount'){
            setWaitOffer(false);
            const rejectMsg = 'ปฏิเสธข้อเสนอของคุณ';
            setMessage(rejectMsg);
            setMessageColor('red');
            setTimeout(() => {
                localStorage.setItem('msgDiscount','');
                window.parent.location = "/main/?query="+query;
            }, 3000);
        }
        // console.log(localStorage.getItem('msgDiscount'));
    }

    // Increase
    function increasePointCoin() {
        let pointInt = '';
        // (amountDiscount.length > 3) ? pointInt = amountDiscount.replaceAll(',', '') : pointInt = amountDiscount;
        (amountDiscount.length > 3) ? pointInt = amountDiscount.split(',').join('') : pointInt = amountDiscount;

        // const result = parseInt(pointInt) + dataDiscountBaht;
        // floor(ค่าเก่า/discount_bht)*discount_bht + discount_bht
        const result = Math.floor(parseInt(pointInt) / dataDiscountBaht) * dataDiscountBaht + dataDiscountBaht;
        const currentPointInt = (askDiscount) ? maxSku : currentPoint;

        // Find max point
        if(result > currentPointInt ) {
            setAmountDiscount(currentPointInt.toLocaleString('en'));
            const diffPoint =  currentPointInt - parseInt(pointInt);
            setAmountCoin(amountCoin + Math.ceil(diffPoint*dataDiscountCoin / dataDiscountBaht));
            setMaxPoint(true);
        } else {
            setAmountDiscount(result.toLocaleString('en'));
            // setAmountCoin(amountCoin + dataDiscountCoin);
            setPointCoin(result);
            setAmountGetCoinVip(amountCoin);
        }

    }

    // Decrease 
    function decreasePointCoin() {
        let pointInt = '';
        // (amountDiscount.length > 3) ? pointInt = amountDiscount.replaceAll(',', '') : pointInt = amountDiscount;
        (amountDiscount.length > 3) ? pointInt = amountDiscount.split(',').join('') : pointInt = amountDiscount;
        
        // const resultPoint = parseInt(pointInt) - dataDiscountBaht;
        // ceil(ค่าเก่า/discount_bht)*discount_bht - discount_bht
        const resultPoint = Math.ceil(parseInt(pointInt) / dataDiscountBaht) * dataDiscountBaht - dataDiscountBaht;
        
        setAmountDiscount(resultPoint.toLocaleString('en-EN'));

        if(amountCoin > 0) {
            // setAmountCoin(amountCoin - dataDiscountCoin);
            setPointCoin(resultPoint);
            setAmountGetCoinVip(amountCoin);
        }
        setMaxPoint(false);
    }

    // Set Point & Coin
    function setPointCoin(point) {
        if(!point) {
            setAmountCoin(0);
        } else {
            let coin = Math.ceil((point/dataDiscountBaht) * dataDiscountCoin);
            setAmountDiscount(point.toLocaleString('en-EN'));
            setAmountCoin(coin);
            setNotEnoughPoint(false);
            setNoTyping(false);
        }
    }

    // Check type number only & update data
    function update(val) {

        if(!val) {            
            setAmountDiscount(0);
            setAmountCoin(0);
        } else  {
            let value = val.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            let valueInt = parseInt(value);

            // let amountDiscountInt = parseInt((amountDiscount.length > 3) ? amountDiscount.replaceAll(',', '') : amountDiscount);
            let amountDiscountInt = parseInt((amountDiscount.length > 3) ? amountDiscount.split(',').join('') : amountDiscount);
            let currentPointInt = (askDiscount) ? maxSku : parseInt(currentPoint);

            // Check limit point
            if(valueInt <= currentPointInt) {
                setNotEnoughPoint(false);
                setAmountDiscount(valueInt.toLocaleString('en-EN'));

                // Set Point & Coin
                setPointCoin(valueInt);   
                setMaxPoint(false);
                setNoTyping(false);
            } else {
                // Check Typing
                if(!noTyping){
                    setNotEnoughPoint(true);
                    setAmountDiscount(valueInt.toLocaleString('en-EN'));
                    setNoTyping(true);
                } else {
                    setNotEnoughPoint(true);
                }
            }
            
        }
    }


    async function fetchDataOfferDiscount() {
        let chargeCoins = amountCoin;

        let dataOfferDiscount = JSON.stringify({
            "charge_coins": chargeCoins,
            "qrcode": dataScanQrCode,
            "qr_refid": dataQrRefId
        });

        const res = await fetch(`https://${apiLocation}/wallet/api/user/offer_discount`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: dataOfferDiscount
        });
  
        res
          .json()
          .then(res => {
            const data = res;
            setMessage(data.message);
            if(data.success == 0) {
                // console.log(data.message.length)
                if(data.message.length === undefined) {
                    setErrorMessage('QR นี้ไม่สามารถใช้ได้ กรุณาใช้ QR ใหม่');
                    setModalError400IsOpen(true);
                } else {
                    setErrorMessage(data.message);
                    setModalError400IsOpen(true);
                }
            } else {
                setWaitOffer(true);
                setModalIsOpen(true);
                waitConfirm();
                let checkStorage = setInterval(setMsgDiscount, 2000);
            }
            
          })
          .catch(err => {
            setError(err.response);
            setModalErrorIsOpen(true);
            setIsLoaded(true);
          });
      }

      async function fetchDataDiscardDiscount() {
        let dataDiscardDiscount = JSON.stringify({
            "qrcode": dataScanQrCode,
            "qr_refid": dataQrRefId
        });

        const res = await fetch(`https://${apiLocation}/wallet/api/user/discard_discount`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: dataDiscardDiscount
        });
  
        res
          .json()
          .then(res => {
            // console.log(res);
            // props.history.push(`/main/?query=${query}`);
            window.parent.location = "/main/?query="+query;
          })
          .catch(err => {
            setError(err);
            setModalErrorIsOpen(true);
            setIsLoaded(true);
          });
      }

      function waitConfirm() {
            console.log('waiting....')
            setTimeout(() => {
                setMessage('คุณทำรายการเกินเวลากำหนด');
            }, 175000);

            setTimeout(() => {
                setModalIsOpen(false);
                handleReload();
            }, 180000);
        }
      
      function handleReload() {
            // props.history.push(`/main/?query=${query}`);
            window.parent.location = "/main/?query="+query;
        }


    Modal.setAppElement('#root');

    if(error) {
        return (
            <Modal 
              isOpen={modalErrorIsOpen} 
              className="_modal box-style modal-couponreceiveaction-error" 
              style={{ 
                  overlay: {
                      backgroundColor: 'rgba(0,0,0,0.5)'
                  }}
              }
            >
              <div className="_error-modal center">
                  <p className="font-black">เกิดข้อผิดพลาดจากการทำรายการ</p>
                  <p className="font-black">กรุณาลองอีกครั้ง</p>
                  <br/>
                  <a onClick={handleReload} >
                      <BtnEllipse name="กลับหน้าหลัก" color="blue"/>
                  </a>
              </div>
            </Modal>
        );
      } else if(!isLoaded) {
        return (
            <div className="container loading">
                <div className="container-top">
                    <h3>Loading...</h3>
                </div>
            </div>
        );
      } else {

        return (
            <div className="receivecoupon-action">
                <div className="action-box box-style">
                    <p className="_heade-top font-black">ส่วนลดสูงสุดที่แลกได้ {maxSku} บาท</p>
                    <div className="_header">
                        <img src={IconTicket} alt="icon" />
                        <span>ส่วนลดที่จะให้</span>&nbsp;<span className="font-black">คุณ&nbsp;{ dataName }&nbsp;{ dataLastName }</span>
                    </div>
                    <div className="_input">
                        {
                            (amountDiscount < dataDiscountBaht ) || (noTyping)? <img className="decreaseBtn" src={IconMinus} alt="icon" /> : <img className="decreaseBtn"  src={IconMinusActive} alt="icon" onClick={decreasePointCoin} />
                        }
                        {/* <span className="amountDiscount">{ amountDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</span> */}
                        <input 
                            type="text" 
                            value={ amountDiscount } 
                            onChange={e => update(e.target.value)} 
                            // className="amountDiscount" 
                            className={ (notEnoughPoint) ? 'amountDiscount font-red' : 'amountDiscount'}
                        />
                        {
                            // amountDiscount > 1000000 ? <img className="increaseBtn"  src={IconPlus} alt="icon" /> : <img className="increaseBtn"  src={IconPlusActive} alt="icon" onClick={increasePointCoin} />
                            (noTyping || maxPoint) ? <img className="increaseBtn"  src={IconPlus} alt="icon" /> : <img className="increaseBtn"  src={IconPlusActive} alt="icon" onClick={increasePointCoin} />
                    }
                        
                    </div>
                    <div className={ (notEnoughPoint) ? '_error show' : '_error'}>
                        { askDiscount ? <p>ไม่สามารถแลกส่วนลดเกิน {maxSku} บาทได้</p> : <p>คุณมีคะแนนไม่เพียงพอ</p> }
                    </div>
                    <div className="_badge">
                        <p className="font-blue-dark">{ dataDiscountCoin } เหรียญ แลกส่วนลดได้ { dataDiscountBaht } บาท</p>
                        <div className="_info">
                            <p>ส่วนลดมากที่สุดที่ให้ได้ <span><b className="font-black">{maxSku.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b> บาท</span></p>
                            <p>เหรียญที่จะถูกหักจากคู่ค้า <span><b className="font-black">{amountCoin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b> เหรียญ</span></p>
                            <p>เหรียญเพิ่มเติมจาก VIP <span><b className="font-black">{Math.floor(amountCoin * dataMarkupPercent / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</b> เหรียญ</span></p>
                        </div>
                    </div>
                    <div className="_footer _display-flex">
                        <div className="_left">
                            <span>เหรียญที่จะได้รับ</span>
                        </div>
                        <div className="_right">
                            <img src={IconStar} alt="icon" />
                            <span className="font-blue">{ (amountCoin + Math.floor(amountCoin * dataMarkupPercent / 100)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</span>
                            <span>เหรียญ</span>
                        </div>
                    </div>
                    <div className="_footer-bottom"><p className="font-blue" onClick={()=> setModalProductIsOpen(true)}>ดูรายการสินค้าที่ขอส่วนลด</p></div>
                </div>
                {/* <Link to={`/scanqrcodeoptional/?query=${query}`}> */}
                <a onClick={()=>fetchDataDiscardDiscount()}>
                    <BtnEllipse name="กลับ" color="grey" />
                </a>
                { 
                    (notEnoughPoint || amountCoin < 1) ? <a style={{ opacity: '0.5'}}><BtnEllipse name="ถัดไป" color="blue" /></a> : <a onClick={()=>fetchDataOfferDiscount()} ><BtnEllipse name="ถัดไป" color="blue" /></a>
                }
                
                <Modal 
                    isOpen={modalIsOpen} 
                    className="_modal box-style receivecouponaction" 
                    style={{ 
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.5)'
                        }}
                    }
                >
                    <div className="_result center" style={{ padding: '60px 40px'}}>
                        {/* { (dataName) ? <p className="font-black">กรุณารอ..</p> : <p></p> }
                        <p className="font-grey" style={{ fontSize: '1.6rem' }}>คุณ {dataName} </p>
                        <p className={ (dataName) ? 'font-black' : 'font-green'}>ตกลงข้อเสนอของคุณ</p> */}
                        { waitOffer ? '' : <p>{dataName}&nbsp; {dataLastName}</p> }
                        <p className={`font-${messageColor}`}>{ message }</p>

                        {/* <a onClick={()=>stopInterval()}> *** </a> */}
                    </div>
                </Modal>

                <Modal 
                isOpen={modalError400IsOpen} 
                className="_modal box-style modal-couponreceiveaction-400" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
                >
                <div className="_error-modal center">
                    {/* <p className="font-black">QR นี้ถูกใช้แล้ว กรุณาใช้ QR ใหม่</p> */}
                    {/* <p className="font-black">{errorMessage}</p> */}
                    <p className="font-black" dangerouslySetInnerHTML={{__html: errorMessage}}></p>
                    <br/>
                    <a onClick={handleReload} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue"/>
                    </a>
                </div>
                </Modal>

                <Modal 
                isOpen={modalProductIsOpen} 
                className="_modal box-style modal-couponreceiveaction-product" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    },
                    content: {
                        transform: 'translate(0, -25%)'
                    }
                }}
                >
                <div className="_error-modal center action-box">
                    {/* <p className="font-black">QR นี้ถูกใช้แล้ว กรุณาใช้ QR ใหม่</p> */}
                    <p className="modal-head font-black">ประเภทสินค้าที่ขอส่วนลด</p>
                    <table>
                        <thead>
                            <tr>
                                <th>ชนิดสินค้า</th>
                                <th>จำนวน</th>
                                <th>ส่วนลด/แถว</th>
                                <th>ส่วนลด</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (skuResult.length > 0) ? listProduct : <tr><td colSpan="4" style={{textAlign: 'center'}}>ไม่มีข้อมูล</td></tr>
                            }
                        </tbody>
                    </table>
                    <div className="_footer _display-flex">
                        <div className="_left">
                            <span>ส่วนลดสูงสุดที่ให้ได้</span>
                        </div>
                        <div className="_right">
                            <img src={IconTicket} alt="icon" />
                            <span className="font-blue">{ maxSku.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </span>
                            <span>บาท</span>
                        </div>
                    </div>
                    <a onClick={()=>setModalProductIsOpen(false)} >
                        <BtnEllipse name="กลับ" color="blue"/>
                    </a>
                </div>
                </Modal>
            </div>
            
        )
    }
}

export default withRouter(CouponReceiveAction);
