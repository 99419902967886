import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Router, withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from '../components/BtnEllipse';

import CouponCreateSkuAction from '../components/CouponCreateSkuAction';

function CreateCouponSku(props) {
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [dataGenQr, setDataGenQr] = useState();
     
    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation');

    useEffect(() => {
        setIsLoaded(true);
        console.log('create coupon sku');

        return (
            setModalErrorIsOpen(false)
        );
    }, [])

    function handleReload() {
        window.parent.location = "/main/?query="+query;
    }

    Modal.setAppElement('#root');

    if(error || modalErrorIsOpen) {
        return (
            <Modal 
            isOpen={error || modalErrorIsOpen} 
            className="_modal box-style modal-createcoupon" 
            style={{ 
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.5)'
                }}
            }
            >
            <div className="_error-modal center">
                <p className="font-black" dangerouslySetInnerHTML={{__html: message}}></p>
                <br/>
                <a onClick={()=>handleReload()} >
                    <BtnEllipse name="กลับหน้าหลัก" color="blue"/>
                </a>
            </div>
            </Modal>
        );
    } else if(!isLoaded) {
            return (
                <div className="container loading">
                    <div className="container-top">
                        <h3>Loading...</h3>
                    </div>
                </div>
            );
    } else {
    return (
        <React.Fragment>
            <div className="container-top">
                <h3 style={{ marginBottom: '25px'}}>แลกส่วนลด</h3>
            </div>
            <CouponCreateSkuAction data={props.data} dataGenQr={dataGenQr} />
        </React.Fragment>
    )
    }
}

export default withRouter(CreateCouponSku);
