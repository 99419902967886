import React, { Component, useState, useRef, useEffect } from 'react';
import { Redirect, Link, Router, withRouter, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import BtnEllipse from '../components/BtnEllipse';

import QrCodeScanner from './QrCodeScanner';
import IconBack from '../assets/img/ic-back.svg';

function QrCodeOptional(props) {
    const [isLoaded, setIsLoaded] = useState(false);

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation'); 

    const [hasImg, setHasImg] = useState(false);
    const [camObj, setCamObj] = useState([]);
    const [camObjSelect, setCamObjSelect] = useState('rear');
    const [modalCameraIsOpen, setModalCameraIsOpen] = useState(false);

    const camID = '';

    const camMockup = [
        {
            id: "92bade7296b7a21ed3f73d4fe2590c7f6d494a7e2395a3fee3f019dc1db749e1",
            label: "FaceTime HD Camera"
        },{
            id: "92bade7296b7a21ed3f73d4fe2590c7f6d494a7e2395a3fee3f019dc1db749e2",
            label: "facing front"
        },{
            id: "92bade7296b7a21ed3f73d4fe2590c7f6d494a7e2395a3fee3f019dc1db749e3",
            label: "facing back"
        },
    ]

    useEffect(() => {
        // eslint-disable-next-line no-undef
        Html5Qrcode.getCameras().then(devices => {
            // alert('Detect Camera >>> '+JSON.stringify(devices))
            if (devices && devices.length) {
                // console.log(devices[0].id);
                setCamObj(devices);
                setModalCameraIsOpen(true);
                camID = devices[0].id;
            } 
            

          }).catch(err => {
            
          });
    }, []);

    function handleReload() {
        // setModalErrorIsOpen(false);
        setModalCameraIsOpen(false);
        window.parent.location = "/main/?query="+query;
      }

    function handleReloadCamera() {
        window.parent.location = "/main/?query="+query;
    }

    Modal.setAppElement('#root');
    return (
        <div className="scanqrcode-box">
            <div className="_header bg-gradient-blue">
                <a onClick={handleReloadCamera}>
                    <div className="btn-back dbrScanner-btn-close">
                        <img src={IconBack} alt="icon" />
                    </div>
                </a>
                <h3>สแกน QR</h3>
                <p>ให้ตำแหน่ง QR code อยู่ตรงกลางภาพ</p>
            </div>

            {/* <img className="qrscanner" src={QrScanner} alt="qrscanner"/> */}
            { isLoaded ? '' : <svg className="dbrScanner-bg-loading" width="20px" height="20px" style={{ zIndex: '0'}} viewBox="0 0 1792 1792"><path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path></svg>}
        
            {/* <div id="camera-reader" className="camera-reader"></div> */}
            <div className="_footer bg-gradient-blue" style={{display: 'none'}}>
                <p>ให้ตำแหน่ง QR code อยู่ตรงกลางภาพ</p>
            </div>

            <Modal 
                isOpen={modalCameraIsOpen} 
                onRequestClose={handleReload}
                shouldCloseOnOverlayClick={true}
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
            <div className="_error-modal center">
                <p>กรุณาเลือกกล้องเพื่อสแกน QR Code</p>
                <br/>
                <ul style={{listStyle: 'none'}}>
                    { camObj.map((option, index) => (
                        <li key={option.id} style={{marginBottom: '10px'}}>
                            <Link to={{
                                    pathname: "/scanqrcode",
                                    search: `?query=${query}`,
                                    cameraId: option.id
                                }}>
                            <BtnEllipse name={(option.label.includes('front') || option.label.includes('Front') || option.label.includes('FRONT') ? 'เปิดใช้กล้องหน้า' : (option.label.includes('back') || option.label.includes('Back') || option.label.includes('BACK') ? 'เปิดใช้กล้องหลัง' : option.label))}  color="blue" />
                            </Link>
                        </li>
                    ))}
                </ul>
                {/* <Link to={`/main/?query=${query}`}><BtnEllipse name="ยกเลิก"  color="grey" /></Link> */}
            </div>
        </Modal>
        </div>
    )
}

export default withRouter(QrCodeOptional);
