import React, { useState, useEffect } from "react";
import { Link, useLocation, withRouter, useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import 'react-tabs/style/react-tabs.css';
import Modal from "react-modal";

import BtnEllipse from "./BtnEllipse";

import IconTicket from "../assets/img/ic-ticket.svg";
import IconVIP from "../assets/img/ic-vip.svg";
import IconStar from "../assets/img/ic-star.svg";
import IconPlus from "../assets/img/ic-plus.svg";
import IconMinus from "../assets/img/ic-minus.svg";
import IconPlusActive from "../assets/img/ic-plus-a.svg";
import IconMinusActive from "../assets/img/ic-minus-a.svg";
import noImg from "../assets/img/noimage.png";

function CouponCreateSkuSalesAction(props) {
  const location = useLocation();
  let history = useHistory();

  const [error, setError] = useState(false);
  const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
  const [modalError400IsOpen, setModalError400IsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [notEnoughPoint, setNotEnoughPoint] = useState(false);
  const [amountDiscount, setAmountDiscount] = useState(0);
  const [amountCoin, setAmountCoin] = useState(0);
  const [amountQuotaCoin, setAmountQuotaCoin] = useState(0);
  const [currentPoint, setCurrentPoint] = useState(0);
  const [noTyping, setNoTyping] = useState(false);
  const [maxPoint, setMaxPoint] = useState(false);

  const [dataName, setDataName] = useState("Name");
  const [dataLastName, setDataLastName] = useState("Last");
  const [dataDiscountCoin, setDataDiscountCoin] = useState(0);
  const [dataDiscountBaht, setDataDiscountBaht] = useState(0);
  const [dataQrRefId, setDataQrRefId] = useState("qrcode refID");
  const [dataScanQrCode, setDataScanQrCode] = useState("qrcode");
  const [dataMarkupPercent, setDataMarkupPercent] = useState(0);
  const [amountGetCoinVip, setAmountGetCoinVip] = useState(0);
  const [message, setMessage] = useState("msg");
  const [messageColor, setMessageColor] = useState("black");
  const [errorMessage, setErrorMessage] = useState(
    "เกิดข้อผิดพลาดจากการทำรายการ กรุณาลองใหม่"
  );

  const apiLocation = localStorage.getItem("apiLocation");
  const accessToken = localStorage.getItem("accessToken");
  const query = localStorage.getItem("query");
  const windowParentLocation = localStorage.getItem("windowParentLocation");

  const skuArrDefault = [];
  const [data, setData] = useState({
    active_sku: props.data.active_sku || 0,
    active_quota: props.data.active_quota || 0,
    sku_result: props.data.sku_result || 0,
    quota_result: props.data.quota_result || 0,
    discount_coin: props.data.campaign_data.discount_coin || 0,
    discount_baht: props.data.campaign_data.discount_baht || 0,
    can_salesdiscount: props.data.campaign_data.can_salesdiscount || 0,
    coins: props.data.coins,
  });
  const [skuSelected, setSkuSelected] = useState(0);
  const [amountSku, setAmountSku] = useState(0);
  const [dataAmountSku, setDataAmountSku] = useState([]);
  const [maxSku, setMaxSku] = useState(0);
  const [startSku, setStartSku] = useState(true);

  const [modalSaleConfirm, setModalSaleConfirm] = useState(false);
  const [salesId, setSalesId] = useState("");
  const [salesRefId, setSalesRefId] = useState("");
  const [errorSalesId, setErrorSalesId] = useState(false);
  const [quotaTypeCoins, setQuotaTypeCoins] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
    // discount_coin , discount_baht
    if (startSku) {
      // Set default array sku
      for (let i = 0; i < data.sku_result.length; i++) {
        skuArrDefault[i] = 0;
      }
      setDataAmountSku(skuArrDefault);
      setDataDiscountCoin(data.discount_coin);
      setDataDiscountBaht(data.discount_baht);
      setStartSku(false);
      setAmountSku(0);
    }
    amountMaxSku();
    // checkDiff();
    if (data.active_quota) {
      // Check qt_type = 'coins' ??
      data.quota_result.map((item) => {
        if (item.qt_type == "coins") {
          setQuotaTypeCoins(true);
          setAmountQuotaCoin(item.amt_remain);
        }
      });
      if (!quotaTypeCoins) {
        setAmountQuotaCoin(data.coins);
      }
    } else {
      setAmountQuotaCoin(data.coins);
    }
  }, [amountSku]);

  function checkDiff() {
    if (amountDiscount > maxSku) {
      setNotEnoughPoint(true);
      setMaxPoint(true);
    } else {
      setNotEnoughPoint(false);
      setMaxPoint(false);
    }
  }

  function amountMaxSku() {
    setAmountDiscount(0);
    setAmountCoin(0);
    setNotEnoughPoint(false);
    setMaxPoint(false);
    setNoTyping(false);

    let amt_sku = 0;
    let amt_sku_discount = [];
    // eslint-disable-next-line array-callback-return
    data.sku_result.map((item, i) => {
      amt_sku_discount[i] = item.discount;
    });
    for (let i = 0; i < dataAmountSku.length; i++) {
      amt_sku += dataAmountSku[i] * amt_sku_discount[i];
    }

    // (amountDiscount > amt_sku) ? setNotEnoughPoint(true) : setNotEnoughPoint(false);

    setMaxSku(amt_sku);
    setCurrentPoint(amt_sku);
    // console.log(dataAmountSku);
    // console.log(amt_sku_discount);
    // console.log('------------------------');
    // console.warn(amt_sku);
    // console.log('------------------------');
  }

  function increaseAmtSku(i) {
    setAmountSku((dataAmountSku[i] += 1));
    amountMaxSku();
  }

  function decreaseAmtSku(i) {
    setAmountSku((dataAmountSku[i] -= 1));
    amountMaxSku();
  }

  function updateSku(i, val) {
    // console.log('index: ' + i);
    // console.log('property name: '+ val);
    let value = val.replace(/[^0-9.]|\./g, "").replace(/(\..*)\./g, "$1");
    let skuArr = [...dataAmountSku];

    !value ? (skuArr[i] = 0) : (skuArr[i] = parseInt(value));

    // amountMaxSku();

    setAmountSku(value);
    setDataAmountSku(skuArr);
  }

  function checkSku() {
    console.log(dataAmountSku);
  }

  function updateSalesDiscount(val) {
    let value = val.replace(/[^a-z0-9]/gi, "");
    setSalesId(value);
  }

  // Increase
  function increasePointCoin() {
    let pointInt = "";
    amountDiscount.length > 3
      ? (pointInt = amountDiscount.split(",").join(""))
      : (pointInt = amountDiscount);

    const result =
      Math.floor(parseInt(pointInt) / dataDiscountBaht) * dataDiscountBaht +
      dataDiscountBaht;

    // Find max point
    if (result > currentPoint) {
      setAmountDiscount(currentPoint.toLocaleString("en"));
      const diffPoint = currentPoint - parseInt(pointInt);
      setAmountCoin(
        amountCoin +
          Math.ceil((diffPoint * dataDiscountCoin) / dataDiscountBaht)
      );
      setMaxPoint(true);
    } else {
      setAmountDiscount(result.toLocaleString("en"));
      // setAmountCoin(amountCoin + dataDiscountCoin);
      setPointCoin(result);
      setAmountGetCoinVip(amountCoin);
    }
  }

  // Decrease
  function decreasePointCoin() {
    let pointInt = "";
    amountDiscount.length > 3
      ? (pointInt = amountDiscount.split(",").join(""))
      : (pointInt = amountDiscount);

    const resultPoint =
      Math.ceil(parseInt(pointInt) / dataDiscountBaht) * dataDiscountBaht -
      dataDiscountBaht;

    setAmountDiscount(resultPoint.toLocaleString("en-EN"));

    if (amountCoin > 0) {
      setPointCoin(resultPoint);
      setAmountGetCoinVip(amountCoin);
    }
    setMaxPoint(false);
  }

  // Set Point & Coin
  function setPointCoin(point) {
    if (!point) {
      setAmountCoin(0);
    } else {
      let coin = Math.ceil((point / dataDiscountBaht) * dataDiscountCoin);
      setAmountDiscount(point.toLocaleString("en-EN"));
      setAmountCoin(coin);
      setNotEnoughPoint(false);
      setNoTyping(false);
    }
  }

  // Check type number only & update data
  function update(val) {
    if (!val) {
      setAmountDiscount(0);
      setAmountCoin(0);
      checkDiff();
    } else {
      let value = val.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      let valueInt = parseInt(value);

      let amountDiscountInt = parseInt(
        amountDiscount.length > 3
          ? amountDiscount.split(",").join("")
          : amountDiscount
      );
      let currentPointInt = parseInt(currentPoint);

      // Check limit point
      if (valueInt <= currentPointInt) {
        setNotEnoughPoint(false);
        setAmountDiscount(valueInt.toLocaleString("en-EN"));
        checkDiff();
        // Set Point & Coin
        setPointCoin(valueInt);

        setMaxPoint(false);
        setNoTyping(false);
      } else {
        checkDiff();
        // Check Typing
        if (!noTyping) {
          setNotEnoughPoint(true);
          setAmountDiscount(valueInt.toLocaleString("en-EN"));
          setNoTyping(true);
        } else {
          setNotEnoughPoint(true);
        }
      }
    }
  }

  // Fetch data discount
  async function fetchDataSalesStart() {
    let sku_select_arr = [];
    data.sku_result.map((item, i) => {
      sku_select_arr[i] = {
        sku_id: item.sku_id.toString(),
        discount: item.discount.toString(),
        amount: dataAmountSku[i].toString(),
      };
    });

    let dataSalesStartBody = JSON.stringify({
      max_discount: maxSku.toString(),
      ask_discount: amountDiscount.split(",").join("").toString(),
      ask_coins: amountCoin.toString(),
      sku_select: sku_select_arr,
    });

    const res = await fetch(
      `https://${apiLocation}/wallet/api/user/sales_start`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: dataSalesStartBody,
      }
    );

    res
      .json()
      .then((res) => {
        if (res.success == 0) {
          setErrorMessage(res.message);
          setModalError400IsOpen(true);
        } else if (res == "Unauthorized") {
          setErrorMessage("กรุณาลองใหม่อีกครั้ง");
          setModalError400IsOpen(true);
        } else {
          // console.warn(res);
          setSalesRefId(res.refid);
          setModalSaleConfirm(true);
        }
      })
      .catch((err) => {
        setError(err.response);
        setModalErrorIsOpen(true);
        setIsLoaded(true);
      });
  }

  // Check ID Employee
  async function fetchDataSalesInputId() {
    let salesInputIdData = JSON.stringify({
      sales_userid: salesId,
      refid: salesRefId,
    });
    // console.log(salesInputIdData)
    const res = await fetch(
      `https://${apiLocation}/wallet/api/user/sales_inputid`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: salesInputIdData,
      }
    );

    res
      .json()
      .then((res) => {
        setErrorMessage("");
        if (res.success == 0) {
          setErrorSalesId(true);
          setSalesId(res.message.split("<br>").join(" "));
          if (Object.keys(res.message).length === 0) {
            setErrorMessage("เกิดข้อผิดพลาด กรุณาใส่รหัสพนักงานใหม่อีกครั้ง");
          } else {
            setErrorMessage(res.message);
          }
        } else if (res == "Unauthorized") {
          setErrorSalesId(true);
          setErrorMessage(res.message);
        } else {
          // console.warn(res);
          history.push({
            pathname: "/confirmcreatecouponsales/",
            search: "?query=" + query,
            state: {
              sales_refid: salesRefId,
              ask_coins: res.sales_campaign[0].ask_coins,
              ask_discount: res.sales_campaign[0].ask_discount,
              coins: res.coins,
            },
          });
        }
      })
      .catch((err) => {
        setError(err.response);
        setModalErrorIsOpen(true);
        setIsLoaded(true);
      });
  }

  function handleReload() {
    // props.history.push(`/main/?query=${query}`);
    window.parent.location = "/main/?query=" + query;
  }

  Modal.setAppElement("#root");

  if (error) {
    return (
      <Modal
        isOpen={modalErrorIsOpen}
        className="_modal box-style modal-couponreceiveaction-error"
        style={{
          overlay: {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        }}
      >
        <div className="_error-modal center">
          <p className="font-black">เกิดข้อผิดพลาดจากการทำรายการ</p>
          <p className="font-black">กรุณาลองอีกครั้ง</p>
          <br />
          <a onClick={handleReload}>
            <BtnEllipse name="กลับหน้าหลัก" color="blue" />
          </a>
        </div>
      </Modal>
    );
  } else if (!isLoaded) {
    return (
      <div className="container loading">
        <div className="container-top">
          <h3>Loading...</h3>
        </div>
      </div>
    );
  } else {
    return (
      <div className="createcouponsku-action">
        <div className="action-box box-style">
          <p className="_heade-top font-black" style={{ textAlign: "center" }}>
            กรุณาเลือกประเภทสินค้า
          </p>
          <div className="sku-option">
            <Tabs onSelect={(index) => setSkuSelected(index)}>
              <div className="_left-option">
                <TabList>
                  {data.sku_result ? (
                    data.sku_result.map((item, i) => {
                      return (
                        <Tab
                          key={i}
                          onClick={() => setAmountSku(dataAmountSku[i])}
                        >
                          {item.sku_name}
                        </Tab>
                      );
                    })
                  ) : (
                    <p className="font-black">ไม่มีข้อมูล SKU</p>
                  )}
                </TabList>
              </div>
              <div className="_right-option">
                {data.sku_result ? (
                  data.sku_result.map((item, i) => {
                    return (
                      <TabPanel key={i}>
                        <div>
                          <div className="sku-img">
                            <img src={item.sku_pic} alt="icon" />
                          </div>
                          <div className="_input sku-input">
                            {!amountSku ? (
                              <img
                                className="decreaseBtn"
                                src={IconMinus}
                                alt="icon"
                              />
                            ) : (
                              <img
                                className="decreaseBtn"
                                src={IconMinusActive}
                                alt="icon"
                                onClick={() => decreaseAmtSku(i)}
                              />
                            )}
                            <input
                              type="text"
                              value={amountSku
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              onChange={(e) => updateSku(i, e.target.value)}
                              onFocus={() =>
                                !amountSku ? setAmountSku("") : amountSku
                              }
                              onBlur={() =>
                                !amountSku || !parseInt(amountSku)
                                  ? setAmountSku(0)
                                  : amountSku
                              }
                              className="amountSku"
                            />
                            <img
                              className="increaseBtn"
                              src={IconPlusActive}
                              alt="icon"
                              onClick={() => increaseAmtSku(i)}
                            />
                          </div>
                        </div>
                      </TabPanel>
                    );
                  })
                ) : (
                  <p className="font-black">ไม่มีข้อมูล SKU</p>
                )}
              </div>
            </Tabs>
          </div>
          <div className="sku-list">
            <ul>
              {data.sku_result ? (
                data.sku_result.map((item, i) => (
                  <li key={i}>
                    <span>{item.sku_name}</span>
                  </li>
                ))
              ) : (
                <p className="font-black">ไม่มีข้อมูล SKU</p>
              )}
            </ul>

            <ul>
              {data.sku_result ? (
                dataAmountSku.map((item, i) => (
                  <li key={i}>
                    <span className="font-black">
                      {item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </li>
                ))
              ) : (
                <p className="font-black">ไม่มีข้อมูล SKU</p>
              )}
            </ul>
          </div>
          {/* Check SKU Button */}
          {/* <p onClick={()=>checkSku()}>Check SKU</p> */}
          <p className="_heade-top font-black">
            ส่วนลดสูงสุดที่แลกได้ {maxSku} บาท
          </p>
          <div className="_header">
            <img src={IconTicket} alt="icon" />
            <span>ส่วนลดที่ต้องการ</span>
          </div>
          <div className="_input">
            {amountDiscount < dataDiscountBaht || noTyping ? (
              <img className="decreaseBtn" src={IconMinus} alt="icon" />
            ) : (
              <img
                className="decreaseBtn"
                src={IconMinusActive}
                alt="icon"
                onClick={decreasePointCoin}
              />
            )}
            {/* <span className="amountDiscount">{ amountDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</span> */}
            <input
              type="text"
              value={amountDiscount}
              onChange={(e) => update(e.target.value)}
              // className="amountDiscount"
              className={
                notEnoughPoint ? "amountDiscount font-red" : "amountDiscount"
              }
              disabled={!maxSku}
            />
            {
              // amountDiscount > 1000000 ? <img className="increaseBtn"  src={IconPlus} alt="icon" /> : <img className="increaseBtn"  src={IconPlusActive} alt="icon" onClick={increasePointCoin} />
              noTyping || maxPoint || !maxSku ? (
                <img className="increaseBtn" src={IconPlus} alt="icon" />
              ) : (
                <img
                  className="increaseBtn"
                  src={IconPlusActive}
                  alt="icon"
                  onClick={increasePointCoin}
                />
              )
            }
          </div>
          <div className={notEnoughPoint ? "_error show" : "_error"}>
            <p>ไม่สามารถแลกส่วนลดเกิน {maxSku} บาทได้</p>
          </div>
          <div className="_badge">
            <p className="font-blue-dark">
              {dataDiscountCoin} เหรียญ แลกส่วนลดได้ {dataDiscountBaht} บาท
            </p>
          </div>
          <div className="_footer _display-flex">
            <div className="_left">
              <span>จำนวนเหรียญที่ใช้</span>
            </div>
            <div className="_right">
              <img src={IconStar} alt="icon" />
              <span className="font-blue">
                {(
                  amountCoin +
                  Math.floor((amountCoin * dataMarkupPercent) / 100)
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
              <span>เหรียญ</span>
            </div>
          </div>
          <div
            className={
              amountCoin > amountQuotaCoin || amountCoin > data.coins
                ? "_error show"
                : "_error"
            }
            style={{ padding: "0" }}
          >
            {data.active_quota && quotaTypeCoins ? (
              <p>คุณมีเหรียญในแคมเปญไม่เพียงพอ</p>
            ) : (
              <p>คุณมีเหรียญสะสมไม่เพียงพอ</p>
            )}
          </div>
        </div>
        {/* <Link to={`/scanqrcodeoptional/?query=${query}`}> */}
        <a href="#" onClick={() => handleReload()}>
          <BtnEllipse name="กลับ" color="grey" />
        </a>
        {notEnoughPoint ||
        amountCoin < 1 ||
        amountCoin > amountQuotaCoin ||
        amountCoin > data.coins ? (
          <a style={{ opacity: "0.5" }}>
            <BtnEllipse name="ถัดไป" color="blue" />
          </a>
        ) : (
          <a onClick={() => fetchDataSalesStart()}>
            <BtnEllipse name="ถัดไป" color="blue" />
          </a>
        )}

        <Modal
          isOpen={modalError400IsOpen}
          className="_modal box-style modal-couponcreatesku-400"
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.5)",
            },
          }}
        >
          <div className="_error-modal center">
            {/* <p className="font-black">QR นี้ถูกใช้แล้ว กรุณาใช้ QR ใหม่</p> */}
            <p
              className="font-black"
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            ></p>
            <br />
            <a onClick={handleReload}>
              <BtnEllipse name="กลับหน้าหลัก" color="blue" />
            </a>
          </div>
        </Modal>

        <Modal
          isOpen={modalSaleConfirm}
          className="_modal box-style modal-couponcreatesku-salediscount"
          style={{
            overlay: {
              backgroundColor: "rgba(0,0,0,0.5)",
            },
          }}
        >
          <div className="_error-modal center action-box">
            <p>ยืนยันว่าใช้ส่วนลดกับพนักงานบริษัท</p>
            <br />
            <div className="_input">
              <input
                className={`input-text ${errorSalesId ? "font-red" : ""}`}
                type="text"
                placeholder="กรุณากรอกรหัสพนักงาน"
                value={salesId}
                onFocus={() => {
                  // eslint-disable-next-line no-sequences, no-unused-expressions
                  setErrorSalesId(false), setSalesId("");
                }}
                onChange={(e) => updateSalesDiscount(e.target.value)}
              />
            </div>
            {/* { errorSalesId ? <p className="font-red" dangerouslySetInnerHTML={{__html: errorMessage}}></p> : null } */}

            <div className="action-box-bottom">
              <a
                onClick={() => {
                  // eslint-disable-next-line no-sequences, no-unused-expressions
                  setModalSaleConfirm(false),
                    setErrorSalesId(false),
                    setSalesId("");
                }}
              >
                <BtnEllipse name="ยกเลิก" color="grey" />
              </a>
              <a onClick={() => fetchDataSalesInputId()}>
                <BtnEllipse name="ยืนยัน" color="blue" />
              </a>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(CouponCreateSkuSalesAction);
