import React, { Component, useState, useRef, useEffect } from 'react';
import QrReader from 'react-qr-scanner';
import { Redirect, Link, Router, withRouter, useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from '../components/BtnEllipse';

import QrScanner from '../assets/img/qrscanner.svg';
import IconBack from '../assets/img/ic-back.svg';
import IconImg from '../assets/img/ic-img.svg';

function QrCodeScanner(props) {
    let history = useHistory();
    const reader = useRef(null);

    const [redLine, setRedLine] = useState(false);
    const [hasImg, setHasImg] = useState(false);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('เกิดข้อผิดพลาดจากการทำงานของระบบ กรุณาลองใหม่');
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [dataScanQrCode, setDataScanQrCode] = useState('ScanQrcode');

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation'); 

    const [camId, setCamId] = useState(props.location.cameraId);
    const [camObjSelect, setCamObjSelect] = useState('rear');

    // eslint-disable-next-line no-undef
    const html5QrCode = new Html5Qrcode("camera-reader");

    useEffect(() => {
        setIsLoaded(true);
        if(props.location.cameraId) {
            getHtml5QrCode(props.location.cameraId)
        } else {
            setErrorMsg("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
            setModalErrorIsOpen(true);
        }

          return () => {
            reader.current = false;
            html5QrCode.stop(); 
          }
    }, []);

    function getHtml5QrCode(cameraId){
        setIsLoaded(true);
        setRedLine(true);

        html5QrCode.start(
            cameraId, 
            {
              fps: 10,    // Optional frame per seconds for qr code scanning
              qrbox: 200,  // Optional if you want bounded box UI
              aspectRatio: 1.7777778
            },
            qrCodeMessage => {
                // console.log(qrCodeMessage);
                setDataAfterScan(qrCodeMessage);
                html5QrCode.stop(); 
            },
            errorMessage => {
              // parse error, ignore it.
            })
          .catch(err => {
            // Start failed, handle it.
        });
    }

    const handleImg = (data) => {
        // console.log('handleImg');
        setHasImg(true);
        setModalIsOpen(true);
      }
    
    const handleScan = (data) => {
        
        if( data || data && hasImg) {
        //   console.log('handleScan: '+data);
            // setDelay(10000);
            setModalIsOpen(false);
            setHasImg(false);
            setModalErrorIsOpen(false);
// console.log(data);
            setDataAfterScan(data);
    
        } else if(!data && hasImg) {
        //   console.log('No Img');
            setTimeout(() => {
                setErrorMsg('QR ไม่ชัดเจน กรุณาลอง QR ใหม่');
                setModalErrorIsOpen(true);
                setModalIsOpen(false);
            }, 3000);
            
            setHasImg(false);
        }
    
      }

    const handleError = (err) => {
        console.error(err);
    }

    const openImageDialog = () => {
        reader.current.openImageDialog();
    }

    // Set data after scan
    async function setDataAfterScan(txt) {
        const dataMsg = JSON.stringify(
            {
                "qrcode": txt
            }
        );
        const res = await fetch(`https://${apiLocation}/wallet/api/user/scan_qrcode`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: dataMsg
        });

        res
        .json()
        .then(res => {
            const data = res;
            if (res.success === 0) {
                if(data.message.name == "TokenExpiredError") {
                    setErrorMsg("QR นี้หมดอายุ กรุณาใช้ QR ใหม่");
                } else if(data.message.name == "JsonWebTokenError") {
                    setErrorMsg("QR นี้ไม่ถูกต้อง กรุณาใช้ QR ใหม่");
                } else {
                    setErrorMsg("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                }
                // setErrorMsg(data.message);
                setModalErrorIsOpen(true);

            } else if(data == "Unauthorized 301") {
                setErrorMsg("QR นี้ไม่สามารถใช้ได้แล้ว กรุณาใช้ QR ใหม่");
                setModalErrorIsOpen(true);

            } else {
                history.push({
                    pathname: '/receivecoupon/',
                    search: '?query='+query,
                    state: { 
                        data: data,
                        qrcode: txt,
                        firstname: data.firstname,
                        lastname: data.lastname,
                        qr_refid: data.qr_refid,
                        conv_coin: data.campaign_qr.conv_coin,
                        conv_point: data.campaign_qr.conv_point,
                        discount_baht: data.campaign_qr.discount_baht,
                        discount_coin: data.campaign_qr.discount_coin,
                        markerup_percent: data.campaign_qr.markerup_percent,
                        max_discount: data.qr_info[0].max_discount || 0,
                        ask_discount: data.qr_info[0].ask_discount || 0,
                        sku_select: data.sku_select || 0
                    }
                })

                localStorage.setItem('scanQrRefId', data.qr_refid);
                setModalIsOpen(false);
            }
        })
        .catch(err => { 
            // setErrorMsg(err);
            setModalErrorIsOpen(true);
            // setIsLoaded(true);
            // console.log(err);
        });
    }

    function handleChange(e) {
        console.log(e.target.value);
        // setCamObjSelect(e.target.value);
        // this.setState({ fruit: e.target.value });
        if(e.target.value === 'facing front') {
            // eslint-disable-next-line no-undef
            setModalCameraIsOpen(false);
        } else {
            setCamObjSelect('rear');
            // eslint-disable-next-line no-undef
            setModalCameraIsOpen(false);
        }
      }

    function handleReload() {
        setModalErrorIsOpen(false);
        window.parent.location = "/main/?query="+query;
      }

    function handleReloadCamera() {
        window.parent.location = "/main/?query="+query;
    }

    Modal.setAppElement('#root');

  return (
    <div className="scanqrcode-box">
        <div className="_header bg-gradient-blue">
            <a onClick={handleReloadCamera}>
                <div className="btn-back dbrScanner-btn-close">
                    <img src={IconBack} alt="icon" />
                </div>
            </a>
            <h3>สแกน QR</h3>
            <p>ให้ตำแหน่ง QR code อยู่ตรงกลางภาพ</p>

            <div className="_browsefile">
                {/* <input type='file' id='file'ref={inputFile} onChange={onIptChange} type="file" multiple accept="image/png,image/jpeg,image/bmp,image/gif" style={{display: 'none'}}/> */}
                <button onClick={openImageDialog}>
                    <img src={IconImg} alt="icon" />
                </button>
            </div>
        </div>

        {/* <img className="qrscanner" src={QrScanner} alt="qrscanner"/> */}
        {/* { isLoaded ? '' : <svg className="dbrScanner-bg-loading" width="20px" height="20px" style={{ position: 'relative', zIndex: '0'}} viewBox="0 0 1792 1792"><path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path></svg>} */}
        
        <div id="camera-reader" className="camera-reader"></div>

        {/* <QrReader
            style={{width: '100vw', height: '100vh'}}
            delay={delay}
            onError={handleError}
            onScan={handleScan}
            facingMode="rear"
        /> */}

        <QrReader
            style={{ display: 'none'}}
            ref={reader}
            onError={handleError}
            onScan={handleScan}
            onImageLoad={handleImg}
            legacyMode
        />

        {/* <div className="_footer bg-gradient-blue">
            <p>ให้ตำแหน่ง QR code อยู่ตรงกลางภาพ</p>
        </div> */}

        <Modal 
                isOpen={modalErrorIsOpen} 
                className="_modal box-style modal-scanqrcode" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        zIndex: 3
                    }}
                }
            >
            <div className="_error-modal center">
                <p>{errorMsg}</p>
                <br/>
                <a onClick={()=>handleReload()} >
                    <BtnEllipse name="กลับหน้าหลัก" color="blue"/>
                </a>
            </div>
        </Modal>

        <Modal 
            isOpen={modalIsOpen} 
            className="_modal box-style modal-scanqrcode" 
            style={{ 
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    zIndex: 3
                }}
            }
            >
                <div className="_error-modal center">
                    <p>Loading...</p>
                    <br/>
                </div>
            </Modal>
    </div>
  )
}

export default withRouter(QrCodeScanner);
