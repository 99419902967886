import React, { useState, useEffect } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import Modal from 'react-modal';

import BtnSquare from './BtnSquare';
import BtnEllipse from './BtnEllipse';

function MainAction(props) {
    const [data, setData] = useState({
        active_quota: props.data.active_quota || 0,
        getDiscount : props.data.campaign_data.get_discount || 0,
        getSaleDiscount : props.data.campaign_data.can_salesdiscount || 0,
        canScanQr : props.data.campaign_data.can_scanqr || 0,
        quota_result: props.data.quota_result || 0,
        active_sku: props.data.active_sku || 0,
    });
    const [statusReached, setStatusReached] = useState(false);
    const [statusReachedCoin, setStatusReachedCoin] = useState(false);
    const [statusZeroCoin, setStatusZeroCoin] = useState(false);
    const [modalCheckQuotaIsOpen, setModalCheckQuotaIsOpen] = useState(false);
    const [activeSku, setActiveSku] = useState(false);

    const query = localStorage.getItem('query');

    useEffect(() => {
        if(data.active_quota) {
            data.quota_result.filter(item=>item.qt_type==='points').map(item=>{
                if(item.status === 'reached' || (item.status === 'tracking' && item.amt_remain === 0) ) {
                    setStatusReached(true);
                } else {
                    setStatusReached(false);
                }
            });
            data.quota_result.filter(item=>item.qt_type==='coins').map(item=>{
                if(item.status === 'reached' ) {
                    setStatusReachedCoin(true);
                } else if(item.status === 'tracking' && item.amt_remain === 0) {
                    setStatusZeroCoin(true);
                } else {
                    setStatusReachedCoin(false);
                }
            });
        }

        if(data.active_sku) {
            setActiveSku(true);
        }

    }, [])

    function handleReload() {
        // props.history.push(`/main/?query=${query}`);
        window.parent.location = "/main/?query="+query;
    }

    return (
        <div className="main-action">
            <ul>

            {/* { !statusReached ? <Link to={`/buycoin/?query=${query}`}><BtnSquare name="แลกเหรียญเพิ่ม" icon="star-add" /></Link> : <div onClick={()=>setModalCheckQuotaIsOpen(true)} ><BtnSquare name="แลกเหรียญเพิ่ม" icon="star-add" /></div> } */}
            { statusReached ? <li><div onClick={()=>setModalCheckQuotaIsOpen(true)} ><BtnSquare name="แลกเหรียญเพิ่ม" icon="star-add" /></div></li> : <li><Link to={`/buycoin/?query=${query}`}><BtnSquare name="แลกเหรียญเพิ่ม" icon="star-add" /></Link></li> }

            { !statusReachedCoin && !statusZeroCoin && data.getDiscount ? <li><Link to={`/createcouponsku/?query=${query}`}><BtnSquare name="แลกส่วนลดกับร้านส่ง" icon="bank" /> </Link></li> : (statusZeroCoin || statusReachedCoin && data.getDiscount ) ? <li><div onClick={()=>setModalCheckQuotaIsOpen(true)} ><BtnSquare name="แลกส่วนลดกับร้านส่ง" icon="bank" /></div></li> : null }

            { (data.getSaleDiscount)  ? <li><Link to={`/createcouponskusales/?query=${query}`}><BtnSquare name="รับส่วนลดจากพนักงาน" icon="sales" /> </Link></li> : null }

            { data.canScanQr ? <li><Link to={`/scanqrcodeoptional/?query=${query}`}><BtnSquare name="รับส่วนลด" icon="scan" /></Link></li> : null }

            </ul>
            <Modal
            isOpen={modalCheckQuotaIsOpen}
            className="_modal box-style modal-checkquota"
            style={{
                overlay: {
                    backgroundColor: 'rgba(0,0,0,0.5)'
                }}
            }
            >
                <div className="_error-modal center">
                    <p className="font-black">ไม่สามารถทำรายการนี้ได้</p>
                    <p className="font-black">เนื่องจากมีผู้ใช้สิทธิ์ครบแล้ว!</p>
                    <br/>
                    <p style={{fontSize: '1.4rem'}}>กรุณารอแลกเหรียญในกิจกรรมรอบถัดไป</p>
                    <br/>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue"/>
                    </a>
                </div>
            </Modal>
        </div>
    )
}

export default withRouter(MainAction);
