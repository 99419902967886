import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from './BtnEllipse';

import IconTicket from '../assets/img/ic-ticket.svg';

function ConfirmCreateCouponAction(props) {
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [modalErrorResIsOpen, setModalErrorResIsOpen] = useState(false)
    const [message, setMessage] = useState('');
    const [messageErrorRes, setMessageErrorRes] = useState('');
    const [dataQrGetBaht, setDataQrGetBaht] = useState(0);

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem("query");
    const windowParentLocation = localStorage.getItem('windowParentLocation');

    const dataCoinsLeft = props.dataCoinsLeft;

    const [data, setData] = useState({
        active_quota: props.data.active_quota || 0,
        quota_result: props.data.quota_result || 0
    });
    const [statusReachedCoins, setStatusReachedCoins] = useState(false);
    const [amtRemain, setAmtRemain] = useState(0);
    const [amtQrChargeCoin, setAmtQrChargeCoin] = useState(0);
    const [closeButtonConfirm, setCloseButtonConfirm] = useState(false);

    useEffect(() => {
        if(data.active_quota) {
            data.quota_result.filter(item=>item.qt_type==='coins').map(item=>{
                setAmtRemain(item.amt_remain);
                setAmtQrChargeCoin(props.dataQrChargeCoins);
                // console.log(amtRemain +', '+amtQrChargeCoin);
            });
        }

    }, [amtRemain, amtQrChargeCoin]);


    async function setDataDiscount(action) {
        setCloseButtonConfirm(true);
        setIsLoaded(false);
        setDataQrGetBaht(props.dataQrGetBaht);
        const userAction = action;
        const qrcode = localStorage.getItem('genQrCode');
        const dataQrRefId = localStorage.getItem('genQrRefId');

        const dataResult = JSON.stringify(
            {
                "action_discount": userAction,
                "qr_refid": dataQrRefId,
                "qrcode": qrcode,
            }
        );

        // console.log(dataResult);
        setModalIsOpen(false);

        const res = await fetch(`https://${apiLocation}/wallet/api/user/action_discount`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer "+accessToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: dataResult
        });

        res
        .json()
        .then(res => {
            setModalIsOpen(false);
            setMessage(res.message);
            // console.log(res);

            if(res.success == 0) {
                setModalErrorResIsOpen(true);
                setMessageErrorRes(res.message);
            } else {

                if(userAction == 'accept'){
                    setModalIsOpen(true);
                } else {
                    window.parent.location = "/main/?query="+query;
                }

            }
        })
        .catch(err => {
            setError(err);
            setModalErrorIsOpen(true);
            setIsLoaded(true);
        });
    }

    function handleReload() {
        setModalIsOpen(false);
        // props.history.push(`/main/?query=${query}`);
        window.parent.location = windowParentLocation;
    }

    Modal.setAppElement('#root');
    return (
        <div className="checkcoin-action">
            <div className="confirm-box box-style">
                <p className="font-black">คุณ {props.dataScanUser} รับส่วนลด</p>
                <div className="_result">
                    <img src={IconTicket} alt="icon" />
                    <span className="font-blue">{props.dataQrGetBaht.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    <span className="font-blue"> บาท</span>
                </div>
            </div>
            <div className="summary-box box-style">
                <div className="_point _display-flex">
                    <div className="_left">
                        <span>จำนวนเหรียญที่ใช้</span>
                    </div>
                    <div className="_right">
                        <span className="font-black">{props.dataQrChargeCoins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                        <span>เหรียญ</span>
                    </div>
                </div>
                <div className="_balance _display-flex">
                    <div className="_left">
                        <span>คงเหลือ</span>
                    </div>
                    <div className="_right">
                        { dataCoinsLeft >= 0 ? <span className="font-black">{dataCoinsLeft.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> : <span className="font-red">{dataCoinsLeft.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>}
                        <span>เหรียญ</span>
                    </div>
                </div>
                {dataCoinsLeft >= 0 ? '': <p className="font-red" style={{textAlign: 'left'}}>คุณมีเหรียญไม่เพียงพอ</p>}
            </div>

            <a onClick={()=>setDataDiscount("reject")}>
                <BtnEllipse name="ยกเลิก" color="grey" />
            </a>

            { dataCoinsLeft < 0  || closeButtonConfirm ? <a style={{ opacity: '0.5'}}><BtnEllipse name="ยืนยัน" color="blue" /></a> : <a onClick={()=>setDataDiscount("accept")} ><BtnEllipse name="ยืนยัน" color="blue" /></a> }

            <Modal
                isOpen={modalIsOpen}
                className="_modal box-style modal-confirmcreatecouponaction"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_result center">
                    <p className="font-black" dangerouslySetInnerHTML={{__html: message}}></p>
                    <div>
                        <img src={IconTicket} alt="icon" />
                        <span className="font-blue">{dataQrGetBaht}</span>
                        <span className="font-blue"> บาท</span>
                    </div>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue" />
                    </a>
                </div>
            </Modal>

            <Modal
                isOpen={modalErrorResIsOpen}
                className="_modal box-style confirmbuycoinaction"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_result center">
                    {/* <p className="font-black">{messageErrorRes}</p> */}
                    <p className="font-black" dangerouslySetInnerHTML={{__html: messageErrorRes}}></p>
                    <a onClick={()=>setDataDiscount("reject")} style={{ opacity: '1'}} ><BtnEllipse name="กลับหน้าหลัก" color="blue" /></a>
                </div>
            </Modal>
        </div>
    )
}

export default withRouter(ConfirmCreateCouponAction);
