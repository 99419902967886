import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Router, withRouter } from 'react-router-dom';
import Modal from 'react-modal';

import BtnEllipse from '../components/BtnEllipse';

import MainTransactionItem from './MainTransactionItem';

function MainTransaction(props) {
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [message, setMessage] = useState('Error');
    const [transaction, setTransaction] = useState([]);

    const apiLocation = localStorage.getItem('apiLocation');
    const accessToken = localStorage.getItem('accessToken');
    const query = localStorage.getItem('query'); 

    useEffect(() => {
        // Get API: Status
        async function fetchDataStatus() {
            const res = await fetch(`https://${apiLocation}/wallet/api/user/wallet_coins_log`, {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer "+accessToken,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            });
    
            res
            .json()
            .then(res => {
                // console.log(res);
                if(res.success == 0) {
                    setModalErrorIsOpen(true);
                } else {
                    const dataTransaction = res;
                    
                    setTransaction(dataTransaction);
                    setIsLoaded(true);
                }
            })
            .catch(err => { 
                // setError(err);
                // setMessage(err);
                // console.log(err);
                setModalErrorIsOpen(true);
                setIsLoaded(true);
            });
        }

        fetchDataStatus();
    }, []);


    function checkTransaction() {
        if(transaction.length > 0) {
            return transaction.map((item,i) => <MainTransactionItem data={item} key={i} />);
        } else {
            return <p style={{ textAlign: 'center', fontSize: '1rem' }}>No Data</p>
        }
    }

    function handleReload() {
        setModalErrorIsOpen(false);
        window.parent.location = "/main/?query="+query;
    }

    Modal.setAppElement('#root');

    if(!isLoaded) {
            return (
                <div className="container loading">
                    <div className="container-top">
                        <h3>Loading...</h3>
                    </div>
                </div>
            );
    } else {

        return (
            <div className="main-transaction">
                <h3 className="main-transaction-title">ประวัติรายการ</h3>
                { checkTransaction() }

                <Modal 
                    isOpen={modalErrorIsOpen} 
                    className="_modal box-style modal-maintransaction" 
                    style={{ 
                        overlay: {
                            backgroundColor: 'rgba(0,0,0,0.5)'
                        }}
                    }
                    >
                    <div className="_error-modal center">
                        <p className="font-black">เกิดข้อผิดพลาดจากประวัติรายการ</p>
                        <p className="font-black">กรุณาลองอีกครั้ง</p>
                        <br/>
                        <a onClick={()=>handleReload()} >
                            <BtnEllipse name="กลับหน้าหลัก" color="blue"/>
                        </a>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(MainTransaction);
