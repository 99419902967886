import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import NoImage from '../assets/img/noimage.png';
import IconVIP from '../assets/img/ic-vip.svg';
import IconStar from '../assets/img/ic-star.svg';

function MainStatus(props) {
    const [data, setData] = useState(props.data);
    
    useEffect(() => {  
   
    }, []);

    return (
        <div className="main-status">
            <div className="main-box box-style">
                <div className="_left">
                    <img src={IconVIP} alt="icon" />
                    <div className="main-text">
                        <p>คะแนน VIP สะสม</p>
                        <h1 className="font-black">{ data.points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</h1>
                    </div>
                </div>
                <div className="_right">
                    <img src={IconStar} alt="icon" />
                    <div className="main-text">
                        <p>ยอดเหรียญสะสม</p>
                        <h1 className="font-blue">{ data.coins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MainStatus);
