import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CouponReceiveAction from '../components/CouponReceiveAction';

function ReceiveCoupon(props) {
    const location = useLocation();

    // const localStorageGetItem = JSON.parse(localStorage.getItem('receivecoupon'));
    // const [dataFromScan, setDataFromScan]= useState(localStorageGetItem);
    const [dataFromScan, setDataFromScan] = useState(location.state);

    const qrRefId = location.state.qr_refid;

    const [data, setData] = useState({
        active_sku: props.data.active_sku || 0
    });
    // const [activeSku, setActiveSku] = useState(false);

    useEffect(() => {
        // console.log(dataFromScan.data);
    }, [location])

    return (
        <React.Fragment>
            <div className="container-top">
                <h3 style={{ marginBottom: '15px'}}>แลกส่วนลด</h3>
                <p className="font-white" style={{ marginBottom: '10px', fontSize: '1.2rem'}}>รหัสอ้างอิง: <span>{qrRefId}</span></p>
            </div>
            <CouponReceiveAction data={props.data} dataFromScan={dataFromScan} />
        </React.Fragment>
    )
}

export default ReceiveCoupon;
