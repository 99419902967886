import React, { useState, useEffect } from 'react';

import IconBack from '../assets/img/ic-back.svg';

import MainStatus from '../components/MainStatus';
import MainAction from '../components//MainAction';
import MainAttention from '../components//MainAttention';
import MainTransaction from '../components//MainTransaction';

function Main(props) {
    const [data, setData] = useState(props.data);
    const [windowParentLocation, setWindowParentLocation] = useState('#')

    useEffect(() => {
        // console.log(data);
        // console.log(localStorage.getItem('windowParentLocation'));
        setWindowParentLocation(localStorage.getItem('windowParentLocation'))
    }, []);

    return (
        <React.Fragment>
            <div className="container-top">
                <a href={windowParentLocation} onClick={sessionStorage.clear()}>
                    <div className="btn-back">
                        <img src={IconBack} alt="icon" />
                    </div>
                </a>
                <h3>สะสมเหรียญ</h3>
                <MainStatus data={data} />
            </div>
            <MainAction data={data}/>
            <MainAttention data={data} />
            <MainTransaction data={props.data}  />
        </React.Fragment>
    );
}

export default Main;
