import React, { useEffect, useState } from 'react';
import { Link, Redirect, Route, useHistory, withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import QRCode from "react-qr-code";

import BtnEllipse from './BtnEllipse';

import NoImage from '../assets/img/noimage.png';

function CouponCreateAction(props) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    // const [windowLocation, setwindowLocation] = useState(props.windowLocation);
    const [dataGenQr, setDataGenQr] = useState(props.dataGenQr);
    const [dataRefId, setDataRefId] = useState(props.dataGenQr.qr_refid);
    const [dataTimeStamp, setDataTimeStamp] = useState(props.dataTimeStamp);

    const url_string = window.location.href;
    const url = new URL(url_string);
    const query = url.searchParams.get("query"); 

    useEffect(() => {
        timerQr();
    }, []);
    
    function timerQr() {
        console.log('waiting...');
        setTimeout(() => {
            setModalIsOpen(true);
        }, 43200000); // unit is millisecond 12hrs = 43,200,000 millisecond
    }

    function handleReload() {
        window.parent.location = "/main/?query="+query;
    }

    Modal.setAppElement('#root');

    return (
        <div className="createcoupon-action">
            <div className="ticket-box box-style">
                <div className="_header">
                    <h3 className="font-black">แลกส่วนลด</h3>
                    <p>{dataTimeStamp}</p>
                    <p>รหัสอ้างอิง: <span>{dataRefId}</span></p>
                </div>
                <div className="_content">
                    <div className="qrcode-box">
                        {/* <img className="qrcode" src={NoImage} alt="qrcode" /> */}
                        {/* <div id="qrcode" className="qrcode"></div> */}
                        {/* <canvas id="qrcode" className="qrcode"></canvas> */}
                        <QRCode value={dataGenQr.qrcode} size={170} />
                    </div>
                </div>
                <div className="_footer">
                    <h3 className="font-black" style={{marginBottom: 0, fontSize: 24}}>ให้ร้านคู่ค้าสแกน QR นี้</h3>
                    <h3 className="font-black" style={{marginBottom: 0, fontSize: 24}}>เพื่อใช้เหรียญแลกส่วนลด ภายใน 12 ชั่วโมง</h3>
                </div>
                <div className="ticket-footer"></div>
            </div>

            <a onClick={()=>handleReload()} >
                <BtnEllipse name="กลับหน้าหลัก" color="blue" />
            </a>

            <Modal 
                isOpen={modalIsOpen} 
                className="_modal box-style genqr" 
                style={{ 
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.5)'
                    }}
                }
            >
                <div className="_result center">
                    <p className="font-black">QR code หมดอายุ</p>
                    <p className="font-black">กรุณาลองใหม่อีกครั้ง</p>
                    <a onClick={()=>handleReload()} >
                        <BtnEllipse name="กลับหน้าหลัก" color="blue" />
                    </a>
                </div>
            </Modal>
        </div>
    )
}

export default withRouter(CouponCreateAction);
