import React from 'react';

import NoImage from '../assets/img/noimage.png';
import IconStarAdd from '../assets/img/ic-star-add.svg';
import IconScan from '../assets/img/ic-scan.svg';
import IconBank from '../assets/img/ic-bank.svg';
import IconEorder from '../assets/img/ic-eordering.svg';
import IconBulk from '../assets/img/ic-bulkoperation.svg';
import IconSales from '../assets/img/ic-sales.svg';


function MainTransactionItem(props) {
    const amount = props.data.coins;
    const amount_status = props.data.event;
// console.log(props.data)
    function checkAmountStatus() {
        if(amount_status=='exchange' || amount_status=='get_coins') {
            return <span className="font-blue">+{amount}</span>

        } else if(amount_status=='accept_discount' || amount_status=='get_discount' || amount_status=='get_sales_discount' || amount_status=='deduct_coin' || amount_status=='deduct_bulk') {
            return <span className="font-red">{amount}</span>

        } else if (amount_status=='add_coin' || amount_status=='add_bulk' || amount_status=='bulk' ) {
            return <span className="font-black">+{amount}</span>

        } else {
            return <span className="font-grey">{amount}</span>
        }
    }

    function checkIcon() {
        if(amount_status=='exchange') {
            return <img src={IconStarAdd} alt="icon" />;

        } else if(amount_status=='accept_discount' || amount_status=='get_discount') {
            return <img src={IconBank} alt="icon" />;

        } else if(amount_status=='get_sales_discount') {
            return <img src={IconSales} alt="icon" />;

        } else if(amount_status=='get_coins') {
            return <img src={IconScan} alt="icon" />;

        } else if(amount_status=='add_coin' || amount_status=='deduct_coin') {
            return <img src={IconEorder} alt="icon" />;

        } else if(amount_status=='add_bulk' || amount_status=='deduct_bulk' || amount_status=='bulk') {
            return <img src={IconBulk} alt="icon" />;

        } else {
            return <img src={NoImage} alt="icon" />;
        }
    }

    return (
        <div className="main-transaction-item box-style">
            <div className="_img">
                { checkIcon() }
            </div>
            <div className="_detail">
                <div className="_status">
                    <span className="font-black">{props.data.description}</span>
                    { checkAmountStatus() }
                </div>
                <div className="_idtimestmp">
                    <span>{props.data.sf_id}</span>
                    <span>{props.data.transaction_time}</span>
                </div>
            </div>
        </div>
    )
}

export default MainTransactionItem;
