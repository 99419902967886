import React, { useState, useEffect } from 'react';
import { Link, Router, withRouter, useHistory } from 'react-router-dom';

import BtnEllipse from './BtnEllipse';

import IconVIP from '../assets/img/ic-vip.svg';
import IconStar from '../assets/img/ic-star.svg';
import IconPlus from '../assets/img/ic-plus.svg';
import IconMinus from '../assets/img/ic-minus.svg';
import IconPlusActive from '../assets/img/ic-plus-a.svg';
import IconMinusActive from '../assets/img/ic-minus-a.svg';

function BuyCoinAction(props) {
    const history = useHistory();

    const [notEnoughPoint, setNotEnoughPoint] = useState(false);
    const [amountPoint, setAmountPoint] = useState(0);
    const [amountCoin, setAmountCoin] = useState(0);
    const [convPoint, setConvPoint] = useState(props.data.campaign_data.conv_point);
    const [convCoin, setConvCoin] = useState(props.data.campaign_data.conv_coin);
    const [currentPoint, setCurrentPoint] = useState(props.data.points);
    const [noTyping, setNoTyping] = useState(false);
    const [maxPoint, setMaxPoint] = useState(false);

    const [data, setData] = useState({
        active_quota: props.data.active_quota || 0,
        quota_result: props.data.quota_result || 0
    })
    const [amtRemain, setAmtRemain] = useState(0);
    const [noQuota, setNoQuota] = useState(false);
    const [statusReached, setStatusReached] = useState(false);

    const query = localStorage.getItem("query"); 

    useEffect(() => {
        if(data.active_quota) {
            data.quota_result.filter(item=>item.qt_type==='points').map(item=>{
                setAmtRemain(item.amt_remain);

                if(item.status === 'reached' || (item.status === 'tracking' && item.amt_remain === 0) ) {
                    setStatusReached(true);
                } else {
                    setStatusReached(false);
                }
            });
        }
        console.warn(amtRemain);
    }, [amtRemain, statusReached])

    function checkQuota(amtCoin) {

        if( amtCoin > amtRemain) {
            setNoQuota(true);
        } else {
            setNoQuota(false);
            history.push({
                pathname: '/confirmbuycoin/',
                search: '?query='+query
            });
        }
    }

    // Increase
    function increasePointCoin() {
        setNoQuota(false);
        
        let pointInt = '';
        // (amountPoint.length > 3) ? pointInt = amountPoint.replaceAll(',', '') : pointInt = amountPoint;
        (amountPoint.length > 3) ? pointInt = amountPoint.split(',').join('') : pointInt = amountPoint;

        const result = parseInt(pointInt) + convPoint;

        // Find max point
        if(result > currentPoint) {
            setAmountPoint(currentPoint.toLocaleString('en'));
            const diffPoint =  currentPoint - parseInt(pointInt);
            setAmountCoin(amountCoin + Math.ceil(diffPoint*convCoin / convPoint));
            setMaxPoint(true);
        } else {
            setAmountPoint(result.toLocaleString('en'));
            setAmountCoin(amountCoin + convCoin);
        }
    }

    // Decrease 
    function decreasePointCoin() {
        setNoQuota(false);

        let pointInt = '';
        // (amountPoint.length > 3) ? pointInt = amountPoint.replaceAll(',', '') : pointInt = amountPoint;
        (amountPoint.length > 3) ? pointInt = amountPoint.split(',').join('') : pointInt = amountPoint;
        
        const resultPoint = parseInt(pointInt) - convPoint;
        
        setAmountPoint(resultPoint.toLocaleString('en-EN'));

        if(amountCoin > 0) {
            setAmountCoin(amountCoin - convCoin);
        }
        setMaxPoint(false);
    }

    // Set Point & Coin
    function setPointCoin(point) {
        setNoQuota(false);

        if(!point) {
            setAmountCoin(0);
        } else {
            let coin = Math.floor((point/convPoint) * convCoin);
            setAmountPoint(point.toLocaleString('en-EN'));
            setAmountCoin(coin);
            setNotEnoughPoint(false);
            setNoTyping(false);
        }
    }

    // Check type number only & update data
    function update(val) {
        setNoQuota(false);
        
        if(!val) {            
            setAmountPoint(0);
            setAmountCoin(0);
        } else  {
            let value = val.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            let valueInt = parseInt(value);

            // let amountPointInt = parseInt((amountPoint.length > 3) ? amountPoint.replaceAll(',', '') : amountPoint);
            let amountPointInt = parseInt((amountPoint.length > 3) ? amountPoint.split(',').join('') : amountPoint);
            let currentPointInt = parseInt(currentPoint);

            // Check limit point
            if(valueInt <= currentPointInt) {
                setNotEnoughPoint(false);
                setAmountPoint(valueInt.toLocaleString('en-EN'));

                // Set Point & Coin
                setPointCoin(valueInt);   

                setNoTyping(false);
            } else {
                // Check Typing
                if(!noTyping){
                    setNotEnoughPoint(true);
                    setAmountPoint(valueInt.toLocaleString('en-EN'));
                    setNoTyping(true);
                } else {
                    setNotEnoughPoint(true);
                }
            }
        }
    }

    function badgeList(params) {
        if(currentPoint < 1) {
            return (
                <div className="_badgelist" style={{opacity: '0.5'}}>
                    <div className="_badgeitem">1,000 คะแนน</div>
                    <div className="_badgeitem">2,000 คะแนน</div>
                    <div className="_badgeitem">3,000 คะแนน</div>
                </div>
            );
        } else {
            return (
                <div className="_badgelist">
                    <div className="_badgeitem" onClick={ ()=> setPointCoin(1000) }>1,000 คะแนน</div>
                    <div className="_badgeitem" onClick={ ()=> setPointCoin(2000) }>2,000 คะแนน</div>
                    <div className="_badgeitem" onClick={ ()=> setPointCoin(3000) }>3,000 คะแนน</div>
                </div>
            );
        }
    }

    function setLocalStorage() {
        let obj = { "point": amountPoint, "coin": amountCoin};
        localStorage.setItem('dataBuyCoin', JSON.stringify(obj)); 

        if(!statusReached && amtRemain > 0) {
            checkQuota(amountCoin);
        } else{
            history.push({
                pathname: '/confirmbuycoin/',
                search: '?query='+query
            });
        }
    }

    function handleReload() {
        window.parent.location = "/main/?query="+query;
    }

    return (
        <div className="buycoin-action">
            <div className="action-box box-style">
                <div className="_header">
                    <img src={IconVIP} alt="icon" />
                    <span>คะแนน VIP ที่ใช้</span>
                </div>
                <div className="_input">
                    {
                        (amountPoint < convPoint ) || (noTyping)? <img className="decreaseBtn" src={IconMinus} alt="icon" /> : <img className="decreaseBtn"  src={IconMinusActive} alt="icon" onClick={decreasePointCoin} />
                    }
                    {/* <span className="amountPoint">{ amountPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</span> */}
                    <input 
                        type="text" 
                        value={ amountPoint } 
                        onChange={e => update(e.target.value)} 
                        // className="amountPoint" 
                        className={ (notEnoughPoint) ? 'amountPoint font-red' : 'amountPoint'}
                    />
                    {
                        (noTyping || maxPoint || currentPoint < 1 ) ? <img className="increaseBtn"  src={IconPlus} alt="icon" /> : <img className="increaseBtn"  src={IconPlusActive} alt="icon" onClick={increasePointCoin} />
                    }
                    
                </div>
                <div className={ (notEnoughPoint || currentPoint < 1) ? '_error show' : '_error'}>
                    <p>คุณมีคะแนนไม่เพียงพอ</p>
                </div>
                <div className={ (noQuota) ? '_error show' : '_error'}>
                    <p>เหลือสิทธิ์ในกิจกรรมนี้ไม่เพียงพอ กรุณารอสิทธิ์รอบถัดไป</p>
                </div>
                <div className="_badge">
                    <p className="font-blue-dark">{convPoint} คะแนน แลกเหรียญได้ {convCoin} เหรียญ</p>
                    { badgeList() }
                </div>
                <div className="_footer _display-flex">
                    <div className="_left">
                        <span>ได้รับเหรียญ</span>
                    </div>
                    <div className="_right">
                        <img src={IconStar} alt="icon" />
                        <span className="font-blue">{ amountCoin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</span>
                        <span>เหรียญ</span>
                    </div>
                </div>
            </div>
            <a onClick={()=>handleReload()} >
                <BtnEllipse name="กลับหน้าหลัก" color="blue" />
            </a>
            { 
                // (notEnoughPoint || amountCoin < 1) ? <Link to="#" style={{ opacity: '0.5'}}><BtnEllipse name="ถัดไป" color="blue" /></Link> : <Link to={`/confirmbuycoin/?query=${query}`} onClick={setLocalStorage()}><BtnEllipse name="ถัดไป" color="blue" /></Link>
                (notEnoughPoint || amountCoin < 1 || noQuota) ? <Link to="#" style={{ opacity: '0.5'}}><BtnEllipse name="ถัดไป" color="blue" /></Link> : <a href="#" onClick={()=>setLocalStorage()}><BtnEllipse name="ถัดไป" color="blue" /></a>
            }
            
        </div>
        
    )
}

export default withRouter(BuyCoinAction);
