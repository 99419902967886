import React, { useState, useEffect } from 'react';

const dataAttention = [
    {
        title: "* ยาเส้น",
        detail: "= 12 บาท/โหล หรือ 240 บาท/ลัง"
    },{
        title: "* แอล แอนด์ เอ็ม 7.1",
        detail: "= 10 บาท/แถว หรือ 500 บาท/ลัง"
    },{
        title: "* แอล แอนด์ เอ็ม ตัวใหญ่",
        detail: "= 30 บาท/แถว หรือ 1,500 บาท/ลัง"
    },{
        title: "* มาร์ลโบโร ทุกรสชาติ",
        detail: "= 50 บาท/แถว หรือ 2,500 บาท/ลัง"
    }

];

function MainAttention(props) {
    const [data, setData] = useState({
        active_quota: props.data.active_quota || 0,
        quota_result: props.data.quota_result || 0,
        description: props.data.campaign_data.description || null
    });
    const [attention, setAttention] = useState([]);

    useEffect(()=>{
        setAttention(dataAttention);
    },[]);

    function diffDay(endDateVal) {
        const now = new Date();
        const nowD = now.getDate();
        const nowM = now.getMonth();
        const nowY = now.getFullYear();

        const end = new Date(endDateVal.replace(/-/g, "/"));
        
        const endD = end.getDate();
        const endM = end.getMonth();
        const endY = end.getFullYear();
        
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const startDate = new Date(nowY, nowM, nowD);
        const endDate = new Date(endY, endM, endD);
        
        const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay));

        return diffDays;
    }

    function showEndDate(endDateVal) {
        const monthThArr = ['ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.','ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'];
        const end = new Date(endDateVal.replace(/-/g, "/"));
        
        const endD = end.getDate();
        const endM = end.getMonth();
        const endY = end.getFullYear();

        const endTime = endDateVal.toString().slice(11,16);

        const valueEnddate = endD+' '+monthThArr[endM]+' '+(endY+543)+' '+endTime;
        return valueEnddate;
    }

    return (
        <div className="main-attention">
            { (data.active_quota) ? 
                <div className="main-quota">
                    { 
                        data.quota_result.map(item=>{
                            return( 
                                (item.end_date && item.status === "tracking") ?
                                    <div className="box-style" key={item.id}>
                                        <p>เหลือเหรียญในแคมเปญที่ใช้{(item.qt_type === "points") ? <span>คะแนนแลก</span> : <span>แลกส่วนลด</span>}ได้ <span className="font-blue">{item.amt_remain || 0} เหรียญ</span> ภายในเวลา <span className="font-blue">{diffDay(item.end_date)} วัน ! ({showEndDate(item.end_date)})</span></p>
                                    </div>
                                : (!item.end_date && item.status === "tracking") ?
                                    <div className="box-style" key={item.id}>
                                        <p>เหลือเหรียญในแคมเปญที่ใช้{(item.qt_type === "points") ? <span>คะแนนแลก</span> : <span>แลกส่วนลด</span>}ได้ <span className="font-blue">{item.amt_remain || 0} เหรียญ</span></p>
                                    </div>
                                : <div className="box-style" key={item.id}><p>เกิดข้อผิดพลาด ไม่สามารถเข้าร่วมแคมเปญได้</p></div>
                            );
                        })
                    }
                </div> : '' }

            <div className="box-style" dangerouslySetInnerHTML={{__html: data.description }}></div>
        </div>
    )
}

export default MainAttention;
